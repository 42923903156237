var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import EstimationInfo from "@components/estimation-info/estimation-info";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function TableExpandableRow(_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = useState(false), isOpenDetail = _c[0], setIsOpenDetail = _c[1];
    var nameRoute = props.moduleTitle.toLocaleLowerCase();
    var IconRow = props.data.typeTransport === "SEA"
        ? " ri-ship-line"
        : props.data.typeTransport === "AIR"
            ? "ri-plane-line"
            : "ri-truck-line";
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "grid grid-cols-12 justify-between px-[30px] py-[15px] border-b" }, { children: [_jsx("div", __assign({ className: "col-span-5" }, { children: _jsxs("div", __assign({ className: "flex item-center" }, { children: [_jsx("i", { className: "".concat(IconRow, " text-size-XL themes-text-brand-v5") }), _jsxs("div", __assign({ className: "ml-5" }, { children: [_jsx("div", __assign({ className: "text-[18px] font-bold" }, { children: props.data.idShipment })), _jsx("div", __assign({ className: "text-[14px]" }, { children: props.data.description }))] }))] })) })), _jsxs("div", __assign({ className: "col-span-7 flex items-center" }, { children: [_jsx("div", __assign({ className: "flex-grow" }, { children: _jsx(EstimationInfo, { initialCountryName: props.data.initialCountryName, initialCountryCode: props.data.initialCountryCode, destinationCountryName: props.data.destinationCountryName, destinationCountryCode: props.data.destinationCountryCode, ETD: props.data.ETD, ETA: props.data.ETA, iconMap: false }) })), props.moreOptions && (_jsxs("div", __assign({ className: "relative" }, { children: [_jsx("i", { className: "ri-more-2-line mx-5 cursor-pointer", onClick: function () { return setIsOpenDetail(!isOpenDetail); } }), isOpenDetail && (_jsx("div", __assign({ className: "absolute z-10 mt-2  bg-white border rounded-sm shadow-lg right-0 w-max" }, { children: props.moreOptions.map(function (itemMore, idx) {
                                            return (_jsx("div", __assign({ className: "py-2 px-5 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                                    navigate("/".concat(nameRoute, "-details/").concat(itemMore.value, "/2"));
                                                } }, { children: itemMore.label }), "item-more-".concat(idx)));
                                        }) })))] }))), _jsx("i", { className: "ri-arrow-down-s-line transform cursor-pointer ".concat(isExpanded ? "rotate-180" : "rotate-0", " transition-transform duration-300 ease-in-out"), onClick: function () {
                                    setIsExpanded(!isExpanded);
                                } })] }))] })), _jsxs("div", __assign({ className: "overflow-hidden px-[30px] grid grid-cols-12\n                                            ".concat(isExpanded ? "h-[300px] transition-height duration-300  py-[15px] border-b" : "h-0 transition-height duration-300") }, { children: [_jsx("div", __assign({ className: "leading-5 col-span-6" }, { children: props.summaryDetail &&
                            props.summaryDetail.map(function (item, idx) {
                                var xLabel = item.labelTranslation
                                    ? t(item.labelTranslation)
                                    : item.label;
                                return (_jsxs("div", __assign({ className: "text-[14px] grid grid-cols-2" }, { children: [_jsxs("div", __assign({ className: "font-bold themes-text-gray-v6" }, { children: [xLabel, ":"] })), _jsx("div", { children: props.data.detail[item.accessor] })] }), "item-detail-".concat(idx)));
                            }) })), _jsx("div", __assign({ className: "flex flex-col col-span-6" }, { children: props.data.track.map(function (data, idx) {
                            var _a;
                            return (_jsxs("div", __assign({ className: "flex items-start relative" }, { children: [_jsxs("div", __assign({ className: "mr-2 flex flex-col items-center" }, { children: [_jsx("div", { className: "rounded-full w-2 h-2 bg-blue-500 line-through z-10" }), idx !== ((_a = props.data.track) === null || _a === void 0 ? void 0 : _a.length) - 1 && (_jsx("div", { className: "absolute top-0 bottom-0 w-[2px] themes-bg-gray-v2" }))] })), _jsxs("div", __assign({ className: "text-[14px] mt-[-4px] pb-5" }, { children: [_jsx("div", __assign({ className: "font-bold" }, { children: data.label })), _jsx("div", { children: data.desc }), _jsx("div", { children: data.time })] }))] }), "data-track-".concat(idx)));
                        }) }))] }))] }));
}
