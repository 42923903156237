import { useState } from 'react';
var useOverlay = function () {
    var _a = useState(false), isOverlayOpen = _a[0], setOverlayOpen = _a[1];
    var openOverlayHandling = function () { return setOverlayOpen(true); };
    var closeOverlayHandling = function () { return setOverlayOpen(false); };
    var toggleOverlayHandling = function () { return setOverlayOpen(!isOverlayOpen); };
    return {
        openOverlayHandling: openOverlayHandling,
        closeOverlayHandling: closeOverlayHandling,
        toggleOverlayHandling: toggleOverlayHandling,
        isOverlayOpen: isOverlayOpen,
    };
};
export default useOverlay;
