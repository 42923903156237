var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './item-list-info.style.css';
import { useTranslation } from 'react-i18next';
var ItemListInfo = function (_a) {
    var itemListInfo = _a.itemListInfo;
    var t = useTranslation().t;
    return (_jsx("div", __assign({ className: "flex" }, { children: itemListInfo.map(function (item, idx) { return (_jsxs("div", __assign({ className: "flex items-center ml-2 text-[12px]" }, { children: [_jsx("div", { className: "w-2 h-2 mr-1 themes-bg-".concat(item.color, " rounded-full") }), _jsx("div", __assign({ className: "min-w-max text-title themes-text-gray-v6" }, { children: item.labelTranslation
                        ? t(item.labelTranslation)
                        : item.label }))] }), "item-list-info-".concat(idx))); }) })));
};
export default ItemListInfo;
