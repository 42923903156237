var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
var SimpleLineChart = function (_a) {
    var data = _a.data, colorPV = _a.colorPV, fillPV = _a.fillPV, colorUV = _a.colorUV, fillUV = _a.fillUV, colorUT = _a.colorUT, fillUT = _a.fillUT;
    var memoizedData = useMemo(function () { return data; }, [data]);
    return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(AreaChart, __assign({ width: 500, height: 300, data: memoizedData, margin: { top: 10, right: 15, left: -25, bottom: -5 } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), colorPV && fillPV && (_jsx(Area, { type: "monotone", dataKey: "pv", stroke: colorPV, fill: fillPV })), colorUV && fillUV && (_jsx(Area, { type: "monotone", dataKey: "uv", stroke: colorUV, fill: fillUV })), colorUT && fillUT && (_jsx(Area, { type: "monotone", dataKey: "ut", stroke: colorUT, fill: fillUT }))] })) })));
};
export default SimpleLineChart;
