import { IMeta } from 'common/common.interface'
import {
    IBookingNote,
    IBookingEDocs,
    IBookingPackLines,
    IBookingMilestone,
    IBookingEvent,
    IBookingRelatedOrder,
    IBookingTracking,
    IBookingDetails,
} from './booking-details.interface'

export const bookingPackLinesDummy: IBookingPackLines[] = [
    {
        id: 1,
        packs: '10',
        weightMeasure: {
            qty: '500',
            unit: 'KG',
        },
        goodsDescription: 'Electronics',
        lengthMeasure: {
            qty: '1.2',
            unit: 'M',
        },
        widthMeasure: {
            qty: '0.8',
            unit: 'M',
        },
        heightMeasure: {
            qty: '0.5',
            unit: 'M',
        },
        volumeMeasure: {
            qty: '1.2',
            unit: 'M3',
        },
    },
    {
        id: 2,
        packs: '20',
        weightMeasure: {
            qty: '2000',
            unit: 'KG',
        },
        goodsDescription: 'Furniture',
        lengthMeasure: {
            qty: '2.0',
            unit: 'M',
        },
        widthMeasure: {
            qty: '1.5',
            unit: 'M',
        },
        heightMeasure: {
            qty: '1.0',
            unit: 'M',
        },
        volumeMeasure: {
            qty: '3.0',
            unit: 'M3',
        },
    },
    {
        id: 3,
        packs: '5',
        weightMeasure: {
            qty: '150',
            unit: 'KG',
        },
        goodsDescription: 'Clothing',
        lengthMeasure: {
            qty: '0.5',
            unit: 'M',
        },
        widthMeasure: {
            qty: '0.4',
            unit: 'M',
        },
        heightMeasure: {
            qty: '0.3',
            unit: 'M',
        },
        volumeMeasure: {
            qty: '0.06',
            unit: 'M3',
        },
    },
    {
        id: 4,
        packs: '12',
        weightMeasure: {
            qty: '800',
            unit: 'KG',
        },
        goodsDescription: 'Books',
        lengthMeasure: {
            qty: '1.0',
            unit: 'M',
        },
        widthMeasure: {
            qty: '0.7',
            unit: 'M',
        },
        heightMeasure: {
            qty: '0.5',
            unit: 'M',
        },
        volumeMeasure: {
            qty: '0.36',
            unit: 'M3',
        },
    },
    {
        id: 5,
        packs: '8',
        weightMeasure: {
            qty: '400',
            unit: 'KG',
        },
        goodsDescription: 'Toys',
        lengthMeasure: {
            qty: '0.6',
            unit: 'M',
        },
        widthMeasure: {
            qty: '0.5',
            unit: 'M',
        },
        heightMeasure: {
            qty: '0.4',
            unit: 'M',
        },
        volumeMeasure: {
            qty: '0.12',
            unit: 'M3',
        },
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const bookingDetails: IBookingDetails = {
    consignorPickupAddress: {
        company: 'OneByOne Logistical PTY LTD',
        address: '567 COLLINS STREET MELBOURNE VIC 3000 AU',
        additionalAddress: '',
        contactName: 'Eason Berg',
        contactPhone: '+6554801203',
        contactMobile: '+6526110200',
        contactEmailAddress: 'eason.berg@gmail.com',
    },
    consignorDocumentaryAddress: {
        company: 'OneByOne Logistical PTY LTD',
        address: '567 COLLINS STREET MELBOURNE VIC 3000 AU',
        additionalAddress: '',
        contactName: 'Eason Berg',
        contactPhone: '+6554801203',
        contactMobile: '+6526110200',
        contactEmailAddress: 'eason.berg@gmail.com',
    },
    consigneePickupAddress: {
        company: 'ABC Company',
        address: '123 road Jakarta Jakarta 000 ID',
        additionalAddress: '',
        contactName: 'Nelson Fergo',
        contactPhone: '+6581810062',
        contactMobile: '+62011002045',
        contactEmailAddress: 'nelson.fergo@gmail.com',
    },
    consigneeDocumentaryAddress: {
        company: 'ABC Company',
        address: '123 road Jakarta Jakarta 000 ID',
        additionalAddress: '',
        contactName: 'Nelson Fergo',
        contactPhone: '+6581810062',
        contactMobile: '+62011002045',
        contactEmailAddress: 'nelson.fergo@gmail.com',
    },
    requestBillToParty: {
        company: 'ABC Company',
        address: '123 road Jakarta Jakarta 000 ID',
        additionalAddress: '',
        contactName: 'Nelson Fergo',
        contactPhone: '+6581810062',
        contactMobile: '+62011002045',
        contactEmailAddress: 'nelson.fergo@gmail.com',
    },

    // origin
    originPort: 'Melbourne',
    etdShipment: '',
    estimatedTimeOfPickup: '',
    pickupRequiredBy: '',
    pickupCartageAdvised: '',
    pickupCartageCompleted: '',
    pickupRequestedBy: '',
    pickupEquipmentNeeded: '',

    // destination
    destinationPort: 'Jakarta',
    etaShipment: '',
    estimatedTimeOfDelivery: '',
    deliveryRequiredBy: '',
    deliveryCartageAdvised: '',
    deliveryCartrageCompleted: '',
    deliveryRequestedBy: '',
    deliveryEquipmentNeeded: '',

    // Charges
    incoTerm: 'Free On Board',
    additionalTerms: '',
    goodsValue: '0.00',
    goodsValueCurrency: 'AUD, Australian Dollar',
    insuranceValue: '0.00',
    insuranceValueCurrency: 'AUD, Australian Dollar',
    chargesApply: 'Show Collect Charges',

    // Shipment Information
    shippersRef: '',
    transportMode: 'Sea Freight',
    containerMode: 'Loose',
    serviceLevel: 'STD, Standard',
    packs: '0',
    outerPackUnit: 'PLT, Pallet',
    volumeMeasure: {
        qty: '',
        unit: 'M3',
    },
    weightMeasure: {
        qty: '2',
        unit: 'KG',
    },
    chargeableWeightOrVolume: {
        qty: '2',
        unit: 'KG',
    },
    goodsDescription: 'Lenovo ThinkPad T14s',
    marksAndNumbers: '',
    startDate: '',
}

export const bookingEDocsDummy: IBookingEDocs[] = [
    {
        fileName: 'invoice_12345.pdf',
        description: 'Invoice for order #12345',
        added: '01 May 2024',
    },
    {
        fileName: 'packing_list_67890.pdf',
        description: 'Packing list for shipment #67890',
        added: '02 May 2024',
    },
    {
        fileName: 'certificate_of_origin.pdf',
        description: 'Certificate of Origin for goods',
        added: '03 May 2024',
    },
    {
        fileName: 'bill_of_lading.pdf',
        description: 'Bill of Lading for container #98765',
        added: '04 May 2024',
    },
    {
        fileName: 'insurance_certificate.pdf',
        description: 'Insurance certificate for shipment',
        added: '05 May 2024',
    },
]

export const bookingNotesDummy: IBookingNote[] = [
    {
        noteText: 'Check-in at 2 PM',
        description: 'Guest should check-in at 2 PM for room availability.',
    },
    {
        noteText: 'Late Arrival',
        description: 'Guest will arrive late around 11 PM.',
    },
    {
        noteText: 'Allergic to Peanuts',
        description:
            'Guest has a severe peanut allergy. Ensure no peanuts in meals.',
    },
    {
        noteText: 'Early Check-out',
        description: 'Guest will check-out early at 6 AM.',
    },
    {
        noteText: 'Extra Towels',
        description: 'Guest requested extra towels in the room.',
    },
]

export const bookingMilestoneDummy: IBookingMilestone[] = [
    {
        description: 'Initial booking request received',
        milestoneEvent: 'Booking Request',
        estimated: '01 May 2024',
        actualStart: '01 May 2024',
    },
    {
        description: 'Booking confirmed by the hotel',
        milestoneEvent: 'Booking Confirmation',
        estimated: '02 May 2024',
        actualStart: '02 May 2024',
    },
    {
        description: 'Payment processed',
        milestoneEvent: 'Payment Processing',
        estimated: '03 May 2024',
        actualStart: '03 May 2024',
    },
    {
        description: 'Guest check-in',
        milestoneEvent: 'Check-In',
        estimated: '10 May 2024',
        actualStart: '10 May 2024',
    },
    {
        description: 'Guest check-out',
        milestoneEvent: 'Check-Out',
        estimated: '15 May 2024',
        actualStart: '15 May 2024',
    },
]

export const bookingEventDummy: IBookingEvent[] = [
    {
        eventTime: '01 May 2024 10:00 AM',
        event: 'Booking Created',
        reference: 'REF123456',
    },
    {
        eventTime: '02 May 2024 12:00 PM',
        event: 'Payment Confirmed',
        reference: 'REF123457',
    },
    {
        eventTime: '10 May 2024 02:00 PM',
        event: 'Guest Checked In',
        reference: 'REF123458',
    },
    {
        eventTime: '15 May 2024 08:00 AM',
        event: 'Guest Checked Out',
        reference: 'REF123459',
    },
    {
        eventTime: '16 May 2024 09:00 AM',
        event: 'Feedback Received',
        reference: 'REF123460',
    },
]

export const bookingRelatedOrderDummy: IBookingRelatedOrder[] = [
    {
        orderNo: 'ORD001',
        orderDate: '01 May 2024',
        bookingConfirmationRef: 'CONF123456',
        bookingConfirmationDate: '02 May 2024',
        orderStatus: 'Confirmed',
        exWorksRequiredBy: '05 May 2024',
        requiredInStore: '10 May 2024',
        transportMode: 'Air',
        containerMode: 'Standard',
        goodsDescription: 'Electronics',
    },
    {
        orderNo: 'ORD002',
        orderDate: '03 May 2024',
        bookingConfirmationRef: 'CONF123457',
        bookingConfirmationDate: '04 May 2024',
        orderStatus: 'Pending',
        exWorksRequiredBy: '07 May 2024',
        requiredInStore: '12 May 2024',
        transportMode: 'Sea',
        containerMode: 'Refrigerated',
        goodsDescription: 'Fresh Produce',
    },
    {
        orderNo: 'ORD003',
        orderDate: '05 May 2024',
        bookingConfirmationRef: 'CONF123458',
        bookingConfirmationDate: '06 May 2024',
        orderStatus: 'Shipped',
        exWorksRequiredBy: '08 May 2024',
        requiredInStore: '13 May 2024',
        transportMode: 'Land',
        containerMode: 'Open Top',
        goodsDescription: 'Machinery',
    },
    {
        orderNo: 'ORD004',
        orderDate: '07 May 2024',
        bookingConfirmationRef: 'CONF123459',
        bookingConfirmationDate: '08 May 2024',
        orderStatus: 'Delivered',
        exWorksRequiredBy: '10 May 2024',
        requiredInStore: '15 May 2024',
        transportMode: 'Rail',
        containerMode: 'Tank',
        goodsDescription: 'Chemicals',
    },
    {
        orderNo: 'ORD005',
        orderDate: '09 May 2024',
        bookingConfirmationRef: 'CONF123460',
        bookingConfirmationDate: '10 May 2024',
        orderStatus: 'In Transit',
        exWorksRequiredBy: '12 May 2024',
        requiredInStore: '17 May 2024',
        transportMode: 'Air',
        containerMode: 'Standard',
        goodsDescription: 'Pharmaceuticals',
    },
]

export const bookingTrackingDummy: IBookingTracking[] = [
    {
        label: 'Arrival',
        desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
        time: 'Aug 1, 2023 00:00',
    },
    {
        label: 'Departure',
        desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
        time: 'Jul 28, 2023 00:00',
    },
    {
        label: 'All Export Documents Received',
        time: 'Mar 2, 2023 19:40',
    },
    {
        label: 'All Import Documents Received',
        time: 'Mar 2, 2023 19:40',
    },
]
