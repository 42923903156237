import { ITabItem } from '@components/tab/tab.interface'
import {
    IContainers,
    IDocumentsReceipts,
    IEvents,
    ILines,
    IMilestones,
    IReceiptsDocs,
    IReletedInvoice,
} from '../receipts.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const TabReceipts: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Container',
        labelTranslationCode: 'tab_details.container',
        key: 'status',
        value: 'container',
        className: 'brand',
    },
    {
        label: 'Lines',
        labelTranslationCode: 'tab_details.lines',
        key: 'status',
        value: 'lines',
        className: 'brand',
    },
    {
        label: 'Related Invoices',
        labelTranslationCode: 'tab_details.related_invoices',
        key: 'status',
        value: 'relatedInvoices',
        className: 'brand',
    },
    {
        label: 'Documents',
        labelTranslationCode: 'tab_details.documents',
        key: 'status',
        value: 'documents',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Messages',
        labelTranslationCode: 'tab_details.messages',
        key: 'status',
        value: 'messages',
        className: 'brand',
    },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    {
        label: 'Events',
        labelTranslationCode: 'tab_details.events',
        key: 'status',
        value: 'events',
        className: 'brand',
    },
]

export const ReceiptsEDocsHeaders: ISTColumn<IReceiptsDocs>[] = [
    {
        label: 'File Name',
        labelTranslation: 'eDocs.file_name',
        accessor: 'fileName',
    },
    {
        label: 'Description',
        labelTranslation: 'eDocs.description',
        accessor: 'description',
    },
    {
        label: 'Added',
        labelTranslation: 'eDocs.added',
        accessor: 'added',
    },
]

export const ReceiptsEDocsDummy: IReceiptsDocs[] = [
    {
        fileName: 'invoice_12345.pdf',
        description: 'Invoice for order #12345',
        added: '01 May 2024',
    },
    {
        fileName: 'packing_list_67890.pdf',
        description: 'Packing list for shipment #67890',
        added: '02 May 2024',
    },
    {
        fileName: 'certificate_of_origin.pdf',
        description: 'Certificate of Origin for goods',
        added: '03 May 2024',
    },
    {
        fileName: 'bill_of_lading.pdf',
        description: 'Bill of Lading for container #98765',
        added: '04 May 2024',
    },
    {
        fileName: 'insurance_certificate.pdf',
        description: 'Insurance certificate for shipment',
        added: '05 May 2024',
    },
]

export const milestoneData = [
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'Pickup Cartage Complete/Finalized',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'Origin Receival from Wharf/Depot',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'All Export Documents Received',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'C000001327',
        milestoneDesc: 'Departure from First Load Port',
        milestoneDate: '11-Jul-2023',
        milestoneStatus: 'overdue',
    },
    {
        parentJob: 'C000001327',
        milestoneDesc: 'Arrival at Final Discharge Port',
        milestoneDate: '22-Jul-2023',
        milestoneStatus: 'overdue',
    },
]

export const DocumentsReceiptsConfigureColumns: IConfigureColumns<IDocumentsReceipts>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'products.packs',
                    accessor: 'packs',
                    label: 'Packs',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'weightMeasure',
                    label: 'Weight Measure',
                    labelTranslation: 'products.weight_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'goodsDescription',
                    label: 'Goods Description',
                    labelTranslation: 'products.goods_description',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'lengthMeasure',
                    label: 'Length Measure',
                    labelTranslation: 'products.length_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'widthMeasure',
                    label: 'Width Measure',
                    labelTranslation: 'products.width_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'heightMeasure',
                    label: 'Height Measure',
                    labelTranslation: 'products.height_measure',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'volumeMeasure',
                    label: 'Volume Measure',
                    labelTranslation: 'products.volume_measure',
                },
            ],
        },
    ]

export const DocumentsReceiptsHeader: ISTColumn<IDocumentsReceipts>[] = [
    {
        accessor: 'packs',
        label: 'Packs',
        labelTranslation: 'products.packs',
    },
    {
        accessor: 'weightMeasure',
        label: 'Weight Measure',
        labelTranslation: 'products.weight_measure',
    },
    {
        accessor: 'goodsDescription',
        label: 'Goods Description',
        labelTranslation: 'products.goods_description',
    },
    {
        accessor: 'lengthMeasure',
        label: 'Length Measure',
        labelTranslation: 'products.length_measure',
    },
    {
        accessor: 'widthMeasure',
        label: 'Width Measure',
        labelTranslation: 'products.width_measure',
    },
    {
        accessor: 'heightMeasure',
        label: 'Height Measure',
        labelTranslation: 'products.height_measure',
    },
    {
        accessor: 'volumeMeasure',
        label: 'Volume Measure',
        labelTranslation: 'products.volume_measure',
    },
]

export const ContainersConfigureColumns: IConfigureColumns<IContainers>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                accessor: 'containerNumber',
                label: 'Container Number',
                labelTranslation: 'receipts.container_number',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'sealNumber',
                label: 'Seal Number',
                labelTranslation: 'receipts.seal_number',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'type',
                label: 'Type',
                labelTranslation: 'receipts.type',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'isPalletised',
                label: 'Is Palletised',
                labelTranslation: 'receipts.is_palletised',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'chargeable',
                label: 'Chargeable',
                labelTranslation: 'receipts.chargeable',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'items',
                label: 'Items',
                labelTranslation: 'receipts.items',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'pallets',
                label: 'Pallets',
                labelTranslation: 'receipts.pallets',
            },
        ],
    },
]

export const ContainersHeader: ISTColumn<IContainers>[] = [
    {
        accessor: 'containerNumber',
        label: 'Container Number',
        labelTranslation: 'receipts.container_number',
    },
    {
        accessor: 'sealNumber',
        label: 'Seal Number',
        labelTranslation: 'receipts.seal_number',
    },
    {
        accessor: 'type',
        label: 'Type',
        labelTranslation: 'receipts.type',
    },
    {
        accessor: 'isPalletised',
        label: 'Is Palletised',
        labelTranslation: 'receipts.is_palletised',
    },
    {
        accessor: 'chargeable',
        label: 'Chargeable',
        labelTranslation: 'receipts.chargeable',
    },
    {
        accessor: 'items',
        label: 'Items',
        labelTranslation: 'receipts.items',
    },
    {
        accessor: 'pallets',
        label: 'Pallets',
        labelTranslation: 'receipts.pallets',
    },
]

export const LinesConfigureColumns: IConfigureColumns<ILines>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                accessor: 'partNumber',
                label: 'Part Number',
                labelTranslation: 'products.part_number',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'description',
                label: 'Description',
                labelTranslation: 'products.description',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'productCode',
                label: 'Product Code',
                labelTranslation: 'products.product_code',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'packUQ',
                label: 'Pack UQ',
                labelTranslation: 'products.pack_uq',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'quantity',
                label: 'Quantity',
                labelTranslation: 'products.quantity',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'totalQuantity',
                label: 'Total Quantity',
                labelTranslation: 'products.total_quantity',
            },
        ],
    },
]

export const LinesHeader: ISTColumn<ILines>[] = [
    {
        accessor: 'partNumber',
        label: 'Part Number',
        labelTranslation: 'products.part_number',
    },
    {
        accessor: 'description',
        label: 'Description',
        labelTranslation: 'products.description',
    },
    {
        accessor: 'productCode',
        label: 'Product Code',
        labelTranslation: 'products.product_code',
    },
    {
        accessor: 'packUQ',
        label: 'Pack UQ',
        labelTranslation: 'products.pack_uq',
    },
    {
        accessor: 'quantity',
        label: 'Quantity',
        labelTranslation: 'products.quantity',
    },
    {
        accessor: 'totalQuantity',
        label: 'Total Quantity',
        labelTranslation: 'products.total_quantity',
    },
]

export const ReletedInvoiceConfigureColumns: IConfigureColumns<IReletedInvoice>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'transactionType',
                    label: 'Transaction Type',
                    labelTranslation: 'invoices.transaction_type',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'terms',
                    label: 'Terms',
                    labelTranslation: 'invoices.terms',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'invoicesDate',
                    label: 'Invoices Date',
                    labelTranslation: 'invoices.invoice_date',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'dueDate',
                    label: 'Due Date',
                    labelTranslation: 'invoices.due_date',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'fullyPaidDate',
                    label: 'Fully Paid Date',
                    labelTranslation: 'invoices.fully_paid_date',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'canceled',
                    label: 'Canceled',
                    labelTranslation: 'invoices.canceled',
                },
                {
                    statusColumn: 'selectedColumns',
                    accessor: 'ledger',
                    label: 'Ledger',
                    labelTranslation: 'invoices.ledger',
                },
            ],
        },
    ]

export const ReletedInvoiceHeader: ISTColumn<IReletedInvoice>[] = [
    {
        accessor: 'transactionType',
        label: 'Transaction Type',
        labelTranslation: 'invoices.transaction_type',
    },
    {
        accessor: 'terms',
        label: 'Terms',
        labelTranslation: 'invoices.terms',
    },
    {
        accessor: 'invoicesDate',
        labelTranslation: 'invoices.invoice_date',
        label: 'Invoices Date',
    },
    {
        accessor: 'dueDate',
        label: 'Due Date',
        labelTranslation: 'invoices.due_date',
    },
    {
        accessor: 'fullyPaidDate',
        label: 'Fully Paid Date',
        labelTranslation: 'invoices.fully_paid_date',
    },
    {
        accessor: 'canceled',
        label: 'Canceled',
        labelTranslation: 'invoices.canceled',
    },
    {
        accessor: 'ledger',
        label: 'Ledger',
        labelTranslation: 'invoices.ledger',
    },
]

export const MilestonesConfigureColumns: IConfigureColumns<IMilestones>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                accessor: 'parentJob',
                label: 'Parent Job',
                labelTranslation: 'milestone.parent_job',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneDesc',
                label: 'Description',
                labelTranslation: 'milestone.milestone_desc',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneDate',
                label: 'Date',
                labelTranslation: 'milestone.milestone_date',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneStatus',
                label: 'Status',
                labelTranslation: 'milestone.milestone_status',
            },
        ],
    },
]

export const MilestonesHeader: ISTColumn<IMilestones>[] = [
    {
        accessor: 'parentJob',
        label: 'Parent Job',
        labelTranslation: 'milestone.parent_job',
    },
    {
        accessor: 'milestoneDesc',
        label: 'Description',
        labelTranslation: 'milestone.milestone_desc',
    },
    {
        accessor: 'milestoneDate',
        label: 'Date',
        labelTranslation: 'milestone.milestone_date',
    },
    {
        accessor: 'milestoneStatus',
        label: 'Status',
        labelTranslation: 'milestone.milestone_status',
    },
]

export const EventsConfigureColumns: IConfigureColumns<IEvents>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                accessor: 'parentJob',
                label: 'Parent Job',
                labelTranslation: 'milestone.parent_job',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneDesc',
                label: 'Description',
                labelTranslation: 'milestone.milestone_desc',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneDate',
                label: 'Date',
                labelTranslation: 'milestone.milestone_date',
            },
            {
                statusColumn: 'selectedColumns',
                accessor: 'milestoneStatus',
                label: 'Status',
                labelTranslation: 'milestone.milestone_status',
            },
        ],
    },
]

export const EventsHeader: ISTColumn<IEvents>[] = [
    {
        accessor: 'parentJob',
        labelTranslation: 'milestone.parent_job',
        label: 'Parent Job',
    },
    {
        accessor: 'milestoneDesc',
        labelTranslation: 'milestone.milestone_desc',
        label: 'Description',
    },
    {
        accessor: 'milestoneDate',
        labelTranslation: 'milestone.milestone_date',
        label: 'Date',
    },
    {
        accessor: 'milestoneStatus',
        labelTranslation: 'milestone.milestone_status',
        label: 'Status',
    },
]
