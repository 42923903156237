var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './button.style.css';
var Button = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, onClick = _a.onClick, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, useUpperCase = _a.useUpperCase, _e = _a.label, label = _e === void 0 ? '' : _e, _f = _a.variant, variant = _f === void 0 ? 'default' : _f, props = __rest(_a, ["type", "isDisabled", "onClick", "isLoading", "useUpperCase", "label", "variant"]);
    var disableButton = isLoading ? true : isDisabled;
    var newLabel = useUpperCase ? label === null || label === void 0 ? void 0 : label.toUpperCase() : label;
    var buttonVariant = 'button-' + variant;
    var hasIcon = props.icon || props.iconSuffix;
    var buttonIconClass = hasIcon && !label ? 'button-icon' : '';
    var customLabel = isLoading ? ('PLEASE WAIT') : (_jsxs(_Fragment, { children: [!props.icon ? undefined : (_jsx("i", { className: "".concat(props.icon, " ").concat(props.iconClassName, " ").concat(newLabel ? 'pr-2' : '') })), newLabel, !props.iconSuffix ? undefined : (_jsx(_Fragment, { children: _jsx("i", { className: "".concat(props.iconSuffix, " ").concat(props.iconClassName, "  ").concat(newLabel ? 'pl-2' : '') }) }))] }));
    return (_jsx("button", __assign({}, props, { type: type, disabled: disableButton, onClick: isDisabled
            ? undefined
            : function () {
                onClick();
            }, className: "loglines-button ".concat(buttonVariant, " ").concat(props.className, " ").concat(buttonIconClass) }, { children: customLabel })));
};
export default Button;
