import Tab from '@components/tab/tab.component'
import { formBookingStatusHeader } from '../create-booking/create-booking.static'
import useEditBooking from './edit-booking.service'
import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'

const EditBooking = () => {
    const { setTabFilter, getContent, navigate, tabFilter, formikEditBooking } =
        useEditBooking()
    const { t } = useTranslation()
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <Tab
                    containerClassName="px-3 pt-3"
                    items={formBookingStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => {
                        setTabFilter(item)
                    }}
                />
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13.8rem)] overflow-auto">
                    {getContent()}
                </div>
                <div className="border-b"></div>
                <div className="p-3 flex justify-between">
                    <Button
                        onClick={() => navigate(-1)}
                        label={t('action.back')}
                        className="w-btnRegularWidth"
                        variant="brand-inverse"
                    />
                    <Button
                        onClick={() => {
                            formikEditBooking.submitForm()
                        }}
                        label={t('action.save')}
                        className="w-btnRegularWidth"
                        variant="brand"
                    />
                </div>
            </div>
        </div>
    )
}

export default EditBooking
