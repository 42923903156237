import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import { useTranslation } from 'react-i18next'

const DeclarationFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Input
                    label={t('declaration.voyage_or_flight_no')}
                    placeholder="Enter Voyage/Flight No"
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'MEL - MElbourne',
                            value: 'MEL - MElbourne',
                        },
                        {
                            label: 'BRI - Brisbane',
                            value: 'BRI - Brisbane',
                        },
                    ]}
                    label={t('declaration.branch_code')}
                    onClick={() => {}}
                    placeholder="Select Branch Code"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'AIR',
                            value: 'air',
                        },
                        {
                            label: 'SEA',
                            value: 'sea',
                        },
                    ]}
                    label={t('declaration.transport_mode')}
                    onClick={() => {}}
                    placeholder="Select Transport Mode"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'JPOSA - Osaka',
                            value: 'osaka',
                        },
                        {
                            label: 'JPUKB - Kobe',
                            value: 'kobe',
                        },
                        {
                            label: 'JPTYO - Tokyo',
                            value: 'tokyo',
                        },
                        {
                            label: 'MYJHB - Johor Bahru',
                            value: 'johorBahru',
                        },
                        {
                            label: 'IDTJP - Tanjung Pinang',
                            value: 'tanjungPinang',
                        },
                    ]}
                    label={t('declaration.origin')}
                    onClick={() => {}}
                    placeholder="Select Origin"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Vessel',
                            value: 'vessel',
                        },
                        {
                            label: 'Date of Arrival',
                            value: 'dateOfArrival   ',
                        },
                        {
                            label: 'House Bill',
                            value: 'houseBill',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', { value: t('action.filter') })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default DeclarationFilter
