var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import emptyImage from '@assets/img/gallery/NotFound.png';
export default function EmptyResult(_a) {
    var message = _a.message, className = _a.className;
    return (_jsxs("div", __assign({ className: "h-full flex flex-col justify-center items-center themes-bg-white gap-4 ".concat(className) }, { children: [_jsx("img", { src: emptyImage, className: "w-[8rem] h-[11rem]" }), _jsx("p", { children: message })] })));
}
