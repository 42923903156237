var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { openInvoice, paymentInvoiceDetail } from "../dummy";
import { Pie, PieChart, ResponsiveContainer } from "recharts";
import Detail from "./detail.component";
import Header from "./header.component";
import FilterSection from "./filter-section.component";
import { useTranslation } from "react-i18next";
function DoughnutChart() {
    // eslint-disable-next-line no-unused-vars
    var _a = useState(0), activeIndex = _a[0], setActiveIndex = _a[1];
    var _b = useState(false), isFilter = _b[0], setIsFilter = _b[1];
    var t = useTranslation().t;
    var onPieEnter = useCallback(function (_, index) {
        setActiveIndex(index);
    }, [setActiveIndex]);
    var renderColorfulLegendText = function (data) {
        return (_jsxs("div", __assign({ className: "row-span-11 " }, { children: [_jsx("div", __assign({ className: "text-gray-400 text-[12px] font-bold font-['Basis Grotesque Arabic Pro']" }, { children: t("dashboard.legend") })), data.map(function (item, index) {
                    var value = item.value, percentage = item.percentage, name = item.name, fill = item.fill, textColor = item.textColor;
                    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("span", __assign({ style: {
                                    color: "#596579",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                } }, { children: t(name) })), _jsx("div", __assign({ className: "text-gray-700 text-[14px] font-bold font-['Basis Grotesque Arabic Pro']" }, { children: "US$ ".concat(value) })), _jsx("div", __assign({ style: { background: "".concat(fill) }, className: "w-[113px] h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex" }, { children: _jsx("div", __assign({ style: { color: "".concat(textColor) }, className: "text-[14px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]" }, { children: percentage })) }))] }), index));
                })] })));
    };
    return (_jsxs("div", __assign({ className: "grid grid-rows-12 h-full" }, { children: [_jsx(Header, { className: "h-5 row-span-1", title: t("dashboard.total_shipments_&_volume_by_mode"), filter: " ".concat(t("dashboard.last_days", {
                    value: 7,
                }), " (17/03/24 - 23/03/24)"), isFilter: isFilter, setIsFilter: setIsFilter }), isFilter ? (_jsx("div", __assign({ className: "lg:row-span-11 md:row-span-11 px-2" }, { children: _jsx(FilterSection, { className: "grid gap-3 mt-6", displayedInform: true, filterBy: true, dateRange: true }) }))) : (_jsxs("div", __assign({ className: "grid grid-cols-2 row-span-11 gap-4" }, { children: [_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%", className: "grid-cols-1" }, { children: _jsx(PieChart, __assign({ className: "content-center", width: 200, height: 200, onMouseEnter: onPieEnter }, { children: _jsx(Pie, { data: openInvoice, cx: "50%", cy: "25%", innerRadius: 40, outerRadius: 95, fill: "#8884d8", paddingAngle: 0, dataKey: "value" }) })) })), _jsxs("div", __assign({ className: "grid-cols-1 grid grid-rows-12 " }, { children: [_jsx(Detail, { className: "grid-cols-2 row-span-1 content-center", data: paymentInvoiceDetail }), renderColorfulLegendText(openInvoice)] }))] })))] })));
}
export default DoughnutChart;
