import { ITabItem } from '@components/tab/tab.interface'
import { BookingDataDummy } from './bookings.dummy'
import { IBookings } from './bookings.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const BookingStatusHeader: ITabItem[] = [
    {
        label: 'All Bookings',
        labelTranslationCode: 'status.all_bookings',
        totalData: BookingDataDummy.length,
        key: 'status',
        value: 'allBookings',
        className: 'brand',
    },
    {
        label: 'Confirmed',
        labelTranslationCode: 'status.confirmed',
        totalData: BookingDataDummy.filter((x: any) => x.status === 'confirmed')
            .length,
        key: 'status',
        value: 'confirmed',
        className: 'green',
    },
    {
        label: 'Booked',
        labelTranslationCode: 'status.booked',
        totalData: BookingDataDummy.filter((x: any) => x.status === 'booked')
            .length,
        key: 'status',
        value: 'booked',
        className: 'yellow',
    },
    {
        label: 'Wait Listed',
        labelTranslationCode: 'status.wait_listed',
        totalData: BookingDataDummy.filter(
            (x: any) => x.status === 'waitListed',
        ).length,
        key: 'status',
        value: 'waitListed',
        className: 'red',
    },
]

export const bookingConfigureColumns: IConfigureColumns<IBookings>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'additional.selected_column',
                label: 'Mode',
                accessor: 'transportMode',
                tdClassName: '!pt-0 !pb-0',
                className: '!h-auto',
                showLabel: false,
                sort: false,
                customBuild: (data) => {
                    let iconTransportMode: string
                    switch (data) {
                        case 'Airplane':
                            iconTransportMode = 'ri-plane-line'
                            break
                        case 'Ship':
                            iconTransportMode = 'ri-ship-line'
                            break
                        case 'Truck':
                            iconTransportMode = 'ri-truck-line'
                            break
                        default:
                            iconTransportMode = 'ri-plane-line'
                    }

                    return (
                        <i className={`${iconTransportMode} text-size-XL`}></i>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'bookings.shipment_id',
                label: 'Shipment ID',
                accessor: 'shipmentID',
                customBuild: (data, row) => {
                    let statusClass: string
                    switch (row?.status) {
                        case 'confirmed':
                            statusClass = 'themes-bg-green-v3 themes-text-white'
                            break
                        case 'booked':
                            statusClass =
                                'themes-bg-yellow-v3 themes-text-white'
                            break
                        case 'waitListed':
                            statusClass = 'themes-bg-red-v3 themes-text-white'
                            break
                        default:
                            statusClass = 'themes-bg-brand-v4 themes-text-white'
                    }
                    return (
                        <div
                            className={`${statusClass}  px-2 rounded w-[95px] flex justify-center`}
                        >
                            {data}
                        </div>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Goods Description',
                labelTranslation: 'products.goods_description',
                accessor: 'goodsDescription',
            },
            {
                statusColumn: 'selectedColumns',
                label: "Shipper's Ref",
                labelTranslation: 'bookings.shippers_reference',
                accessor: 'shippersReference',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Origin',
                labelTranslation: 'bookings.origin',
                accessor: 'origin',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Destination',
                labelTranslation: 'bookings.destination',
                accessor: 'destination',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Packs',
                labelTranslation: 'products.packs',
                accessor: 'packs',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Weight Measure',
                labelTranslation: 'products.weight_measure',
                accessor: 'weightMeasure',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Volume Measure',
                labelTranslation: 'products.volume_measure',
                accessor: 'volumeMeasure',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Master Bill',
                labelTranslation: 'bookings.master_bill',
                accessor: 'masterBill',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Load Port',
                labelTranslation: 'bookings.load_port',
                accessor: 'loadPort',
            },
            {
                statusColumn: 'availableColumns',
                label: 'Updated By',
                labelTranslation: 'bookings.updated_by',
                accessor: 'updatedBy',
            },
        ],
    },
]
