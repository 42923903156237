import { useState } from 'react'
import Dropdown from '@components/dropdown/dropdown.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import Header from './header.component'
import { IDashboardItem } from 'client/ifb/pages/dashboard/row1/row1.dummy'
import { optionDates } from '../performances.dummy'
import { useTranslation } from 'react-i18next'

interface ProgressProps {
    data: IDashboardItem
}

function Progress({ data }: ProgressProps) {
    const { title, total, summaryTotal, isIncrease } = data
    const { t } = useTranslation()
    const [isFilter, setIsFilter] = useState<boolean>(false)

    const [dateOption, setDateOption] = useState<
        IDropdownItem<unknown> | undefined
    >({
        value: '7D',
        label: t('dashboard.last_days', {
            value: 7,
        }),
    })

    return (
        <div className="flex flex-col justify-start h-[100%]">
            {/* Title */}
            <Header
                className=""
                title={title}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                filter={t('dashboard.last_days', {
                    value: 7,
                })}
            />
            {isFilter ? (
                <div
                    className={`grid 2xl:row-span-12 2xl:gap-[4px] md:row-span-6 md:gap-[4px] sm:row-span-6 sm:gap-[5px]`}
                >
                    <Dropdown
                        options={optionDates}
                        onClick={(value) => {
                            setDateOption(value)
                        }}
                        className="customDropdown"
                        useBorder={true}
                        value={dateOption}
                    />
                </div>
            ) : (
                <>
                    {/* Summary */}
                    <div className={`grid transition-opacity`}>
                        <div className="items-center gap-[12px] inline-flex">
                            <div className="text-gray-700 lg:text-[20px] md:text-[32px] font-bold font-['Bahnschrift']">
                                {total}
                            </div>
                            <div
                                className={`p-[5px] self-center ${isIncrease ? 'bg-green-100' : 'bg-red-100'} px-1.5 rounded flex-col justify-center items-center gap-2.5 inline-flex`}
                            >
                                <div className="justify-start items-center inline-flex">
                                    <div className="text-zinc-600 lg:text-[14px] md:text-[20px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]">
                                        {summaryTotal}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Progress
