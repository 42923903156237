import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'

interface HeaderProps {
    className: string
    title: string
    filter: string
    isFilter: boolean
    setIsFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({
    className,
    title,
    filter,
    isFilter,
    setIsFilter,
}: HeaderProps) => {
    const { t } = useTranslation()
    return (
        <div
            className={`${className} content-center grid grid-cols-12  h-full`}
        >
            <div className="col-span-10 justify-self-start self-center text-gray-400 lg:text-[12px] md:text-xs">
                <div
                    className={`self-end  font-bold   ${isFilter ? 'themes-text-brand-v5' : 'text-gray-400'}`}
                >
                    {title}
                </div>
                <div
                    className={`self-start font-normal  ${isFilter ? 'themes-text-brand-v5' : 'text-gray-400'}`}
                >
                    {filter}
                </div>
            </div>
            <div className="col-span-2 row-span-2 self-center justify-self-end">
                <Button
                    onClick={() => {
                        setIsFilter(!isFilter)
                    }}
                    label={
                        isFilter ? t('dashboard.save') : t('dashboard.filter')
                    }
                    variant={isFilter ? 'brand-v2' : 'default'}
                    className="w-[70px] !h-5 !pt-[1px] rounded justify-center items-center gap-2.5 inline-flex  lg:text-[12px] md:text-xs font-bold"
                />
            </div>
        </div>
    )
}

export default Header
