import Tab from '@components/tab/tab.component'
import { formBookingStatusHeader } from '../create-booking/create-booking.static'
import useBookAgain from './book-again.service'
import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'

const BookAgain = () => {
    const {
        setTabFilter,
        getContent,
        setIsPreview,
        navigate,
        formikPlaceBooking,
        isPreview,
        formikBookAgain,
        tabFilter,
    } = useBookAgain()
    const { t } = useTranslation()
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <Tab
                    containerClassName="px-3 pt-3"
                    items={formBookingStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => {
                        setTabFilter(item)
                    }}
                />
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13.8rem)] overflow-auto">
                    {getContent()}
                </div>
                <div className="border-b"></div>
                <div className="p-3 flex justify-between">
                    <Button
                        onClick={() => {
                            isPreview ? setIsPreview(false) : navigate(-1)
                        }}
                        label={t('action.back')}
                        useUpperCase={true}
                        className="w-btnRegularWidth"
                        variant="brand-inverse"
                    />
                    <Button
                        onClick={() => {
                            isPreview
                                ? formikPlaceBooking.submitForm()
                                : formikBookAgain.submitForm()
                        }}
                        label={
                            isPreview
                                ? t('bookings.place_booking')
                                : t('bookings.preview_booking')
                        }
                        useUpperCase={true}
                        className="w-btnRegularWidth"
                        variant="brand"
                    />
                </div>
            </div>
        </div>
    )
}

export default BookAgain
