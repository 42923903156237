import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import VolumesCharts from './volumes-charts.component'
import Dropdown from '@components/dropdown/dropdown.component'
import Button from '@components/button/button.component'
import useVolumes from './volumes.service'
import {
    OceanFCL,
    LandFCL,
    ShipmentsDummy,
    VolumeDummy,
    WeightDummy,
    RailFCL,
    AirFCL,
    RoadFCL,
    TotalFCL,
} from './volumes.dummy'
import { optionsFilterVolumes, ShipmentMode } from './volume.static'
import { IFilterVolumeItems } from './volumes.interface'
import BarChartCustom from '@components/rechart-component/bar/bar-chart.component'
import { useTranslation } from 'react-i18next'

function Volumes() {
    const { formLine, deleteFormLine, updateFilterType } = useVolumes()
    const { t } = useTranslation()
    return (
        <div className="grid gap-2 lg:h-[calc(100vh-180px)] lg:grid-rows-12">
            <div className="grid gap-2 lg:grid-cols-2 lg:row-span-6 md:grid-cols-1">
                {/*  ------------  */}

                {/* Filter */}
                <CardsDashboard className="flex flex-col">
                    <div className=" w-full px-[20px] py-[10px] border-b themes-border-color themes-text-gray-v3 font-light">
                        {t('action.filter')}
                    </div>
                    <div className="w-full px-[20px] py-[15px] flex-grow overflow-y-auto">
                        {formLine.map((data, idx) => {
                            return (
                                <div
                                    key={`filter-item-volume-${idx}`}
                                    className="flex w-full justify-between gap-2 pb-2"
                                >
                                    <div className="w-full">
                                        <Dropdown
                                            options={optionsFilterVolumes}
                                            label={t('volumes.filter_type')}
                                            onClick={(selectedOption) => {
                                                if (selectedOption) {
                                                    updateFilterType(
                                                        idx,
                                                        selectedOption as IFilterVolumeItems,
                                                    )
                                                }
                                            }}
                                            placeholder={t('action.select', {
                                                value: t('action.filter'),
                                            })}
                                            isClearable={true}
                                            isSearchable={true}
                                        />
                                    </div>

                                    <div
                                        className={`w-full ${data.filterType === '' && 'opacity-0'}`}
                                    >
                                        <Dropdown
                                            options={[
                                                {
                                                    label: 'IDJKT - Jakarta, Java',
                                                    value: 'IDJKT - Jakarta, Java',
                                                },
                                                {
                                                    label: 'THBKK - Bangkok',
                                                    value: 'THBKK - Bangkok',
                                                },
                                                {
                                                    label: 'USLAX - Los Angeles',
                                                    value: 'USLAX - Los Angeles',
                                                },
                                                {
                                                    label: 'USNYC - New York',
                                                    value: 'USNYC - New York',
                                                },
                                            ]}
                                            label={data.filter.label}
                                            onClick={() => {}}
                                            placeholder={`Select ${data.filter.label}`}
                                            isClearable={true}
                                            isSearchable={true}
                                        />
                                    </div>
                                    <div className="w-[50px]">
                                        {data.filter.label !== '' && (
                                            <i
                                                className={`ri-delete-bin-line themes-text-hover-red-v3 text-[26px] cursor-pointer `}
                                                onClick={() =>
                                                    deleteFormLine(idx)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="w-full px-[20px] py-[10px] flex gap-2 justify-end border-t">
                        <Button
                            onClick={async () => {}}
                            label={t('volumes.clear_all_filter')}
                            className=""
                            variant="brand-inverse"
                        />
                        <Button
                            onClick={() => {}}
                            label={t('volumes.manage_layout')}
                            className=""
                            variant="brand-inverse"
                        />
                    </div>
                </CardsDashboard>

                {/* Regional Route */}
                <CardsDashboard className="grid grid-rows-12">
                    <div className="row-span-2 themes-text-gray-v3 font-light text-[12px]">
                        <div className="font-bold">
                            {t('volumes.number_of_shipments_by')}
                        </div>
                        <div>{t('volumes.regional_route')}</div>
                    </div>
                    <div className="row-span-10 w-full">
                        <BarChartCustom
                            data={ShipmentMode}
                            className="w-full"
                        />
                    </div>
                </CardsDashboard>
            </div>
            <div className="grid gap-2 lg:grid-cols-6 lg:row-span-6 md:grid-cols-2">
                {/* List of Cards */}
                <CardsDashboard className="lg:h-[calc(50vh-15px)] p-2">
                    <VolumesCharts
                        isMode={true}
                        summary={OceanFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
                <CardsDashboard className="lg:h-[calc(50vh-15px)]">
                    <VolumesCharts
                        isMode={true}
                        summary={LandFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
                <CardsDashboard className="lg:h-[calc(50vh-15px)]">
                    <VolumesCharts
                        isMode={true}
                        summary={RailFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
                <CardsDashboard className="lg:h-[calc(50vh-15px)]">
                    <VolumesCharts
                        isMode={true}
                        summary={AirFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
                <CardsDashboard className="lg:h-[calc(50vh-15px)]">
                    <VolumesCharts
                        isMode={true}
                        summary={RoadFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
                <CardsDashboard className="lg:h-[calc(50vh-15px)]">
                    <VolumesCharts
                        isMode={false}
                        summary={TotalFCL}
                        data1={ShipmentsDummy()}
                        data2={VolumeDummy()}
                        data3={WeightDummy()}
                    />
                </CardsDashboard>
            </div>
        </div>
    )
}

export default Volumes
