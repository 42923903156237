var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./modal.style.css";
var Modal = function (_a) {
    var children = _a.children, isModalOpen = _a.isModalOpen, className = _a.className;
    var disActiveClassName = "z-[-9999] fade-out pointer-events-none";
    var activeClassName = "z-[9999] fade-in bg-opacity-75 bg-gray-800";
    var modalClassName = isModalOpen ? activeClassName : disActiveClassName;
    return (_jsx("div", __assign({ className: "loglines-modal fixed inset-0 bg-opacity-0 flex items-center justify-center  ".concat(modalClassName) }, { children: _jsx("div", __assign({ className: "bg-white p-4 rounded w-3/4 sm:!w-full sm:m-4 ".concat(className) }, { children: children })) })));
};
export default Modal;
