var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input from "@components/input/input.component";
import { formatDate } from "@services/common.service";
import { useTranslation } from "react-i18next";
import "./date-picker.style.css";
var DatePickerInput = function (_a) {
    var _b;
    var selectedDate = _a.selectedDate, disable = _a.disable, selectedRange = _a.selectedRange, isRange = _a.isRange, label = _a.label, onClear = _a.onClear, setShowDatePicker = _a.setShowDatePicker, _c = _a.iconPosition, iconPosition = _c === void 0 ? "right" : _c, props = __rest(_a, ["selectedDate", "disable", "selectedRange", "isRange", "label", "onClear", "setShowDatePicker", "iconPosition"]);
    var t = useTranslation().t;
    return (_jsx("div", { children: isRange ? (_jsxs("div", __assign({ className: "flex rounded-[5px] bg-transparent relative gap-2" }, { children: [_jsxs("div", __assign({ className: "flex w-full relative" }, { children: [_jsx("i", { className: "ri-calendar-2-fill absolute left-2 top-[6px] z-[2]" }), _jsx(Input, { label: t("additional.start_date"), onClick: function () {
                                setShowDatePicker(true);
                            }, value: (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from)
                                ? formatDate((_b = selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from) === null || _b === void 0 ? void 0 : _b.toString())
                                : "", className: "text-sm placeholder:text-sm !h-[45px] !w-full pl-8 !text-size-M  ".concat(disable ? "themes-bg-gray-v5" : "themes-bg-white"), placeholder: "DD/MM/YYYY" }), (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from) && (_jsx("i", { className: "ri-close-circle-fill absolute right-3 top-2 cursor-pointer", onClick: onClear }))] })), _jsxs("div", __assign({ className: "flex w-full relative" }, { children: [_jsx("i", { className: "ri-calendar-2-fill absolute left-2 top-[6px] z-[2]" }), _jsx(Input, { label: t("additional.end_date"), onClick: function () {
                                setShowDatePicker(true);
                            }, value: (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.to) ? formatDate(selectedRange.to.toString()) : "", className: "text-sm placeholder:text-sm !h-[45px] !w-full pl-8 !text-size-M ".concat(disable ? "themes-bg-gray-v5" : "themes-bg-white"), placeholder: "DD/MM/YYYY" }), (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.to) && (_jsx("i", { className: "ri-close-circle-fill absolute right-3 top-2 cursor-pointer", onClick: onClear }))] }))] }))) : (_jsxs("div", __assign({ className: "flex" }, { children: [iconPosition === "left" && (_jsx("div", { className: "ri-calendar-event-line border-r px-2 my-2 flex items-center mr-[-36px] z-[1] cursor-pointer", onClick: function () {
                        setShowDatePicker(true);
                    } })), _jsx(Input, { onChange: function () { }, onClick: function () {
                        setShowDatePicker(true);
                    }, value: selectedDate ? formatDate(selectedDate.toString()) : "", className: "text-sm placeholder:text-sm ".concat(iconPosition === "left" && "pl-10", " caret-transparent ").concat(props.containerInputClassName, " "), label: label, placeholder: "DD/MM/YYYY", disabled: disable }), iconPosition === "right" && (_jsx("div", { className: "ri-calendar-event-line border-l px-2 my-2 flex items-center ml-[-36px] z-[1]  ".concat(disable ? "" : "cursor-pointer", " "), onClick: function () {
                        if (!disable)
                            setShowDatePicker(true);
                    } }))] }))) }));
};
export default DatePickerInput;
