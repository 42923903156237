import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import Progress from '../components/progress.component'
import useDashboard from '../dashboard.service'

const Row1 = () => {
    const { dataCard } = useDashboard()
    return (
        <>
            {dataCard.map((item, index) => {
                return (
                    <CardsDashboard
                        key={index}
                        className="lg:row-end-1 md:row-span-12 sm:col-span-6 overflow-hidden  pb-[10px]"
                    >
                        <Progress data={item} />
                    </CardsDashboard>
                )
            })}
        </>
    )
}

export default Row1
