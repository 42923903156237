import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useTracking = ({
    handleSearch,
    initialSearch,
}: {
    // eslint-disable-next-line no-unused-vars
    handleSearch?: (values: string) => void
    initialSearch?: string
}) => {
    const { t } = useTranslation()
    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => {
            handleSearch && handleSearch(values.searchTerm)
        },
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = t('additional.search_term')
            }
            return errors
        },
    })

    useEffect(() => {
        if (!initialSearch) return
        formik.setValues({ searchTerm: initialSearch })
    }, [initialSearch])

    return {
        formik,
    }
}

export default useTracking
