import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'

const LogisticsEntitiesForm = ({
    entity,
    companyOptions,
    contactNameOptions,
}: {
    entity: string
    companyOptions: IDropdownItem[]
    contactNameOptions: IDropdownItem[]
}) => {
    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-2 gap-3">
            <FormDropdown
                label={t('bookings.company')}
                name={`${entity}.company`}
                options={companyOptions}
                placeholder={t('action.enter', {
                    value: t('bookings.company'),
                })}
                parentDivClassName="!mb-0 col-span-2"
                isClearable={true}
            />
            <FormInput
                label={t('bookings.street')}
                name={`${entity}.street`}
                placeholder={t('action.enter', {
                    value: t('bookings.street'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.additional_street')}
                name={`${entity}.additionalStreet`}
                placeholder={t('action.enter', {
                    value: t('bookings.additional_street'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.city')}
                name={`${entity}.city`}
                placeholder={t('action.enter', {
                    value: t('bookings.city'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.state')}
                name={`${entity}.state`}
                placeholder={t('action.enter', {
                    value: t('bookings.state'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.postcode')}
                name={`${entity}.postcode`}
                placeholder={t('action.enter', {
                    value: t('bookings.postcode'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.country')}
                name={`${entity}.country`}
                placeholder={t('action.enter', {
                    value: t('bookings.country'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormDropdown
                label={t('bookings.contact_name')}
                name={`${entity}.contactName`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_name'),
                })}
                parentDivClassName="!mb-0 col-span-2"
                options={contactNameOptions}
            />
            <FormInput
                label={t('bookings.contact_email_address')}
                name={`${entity}.contactEmailAddress`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_email_address'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.contact_phone')}
                name={`${entity}.contactPhone`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_phone'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.contact_mobile')}
                name={`${entity}.contactMobile`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_mobile'),
                })}
                parentDivClassName="!mb-0"
            />
        </div>
    )
}

export default LogisticsEntitiesForm
