var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var EstimationInfo = function (_a) {
    var initialCountryName = _a.initialCountryName, initialCountryCode = _a.initialCountryCode, destinationCountryName = _a.destinationCountryName, destinationCountryCode = _a.destinationCountryCode, ETD = _a.ETD, ETA = _a.ETA, iconMap = _a.iconMap;
    var originImgCode = initialCountryCode.slice(0, 2).toLowerCase();
    var destinationImgCode = destinationCountryCode.slice(0, 2).toLowerCase();
    var originImg = "https://flagcdn.com/".concat(originImgCode, ".svg");
    var destinationImg = "https://flagcdn.com/".concat(destinationImgCode.toLowerCase(), ".svg");
    return (_jsxs("div", __assign({ className: 'mt-2 flex ' }, { children: [_jsxs("div", __assign({ className: 'flex items-center', style: { lineHeight: '14px' } }, { children: [iconMap && _jsx("i", { className: "ri-map-pin-line mr-1" }), _jsx("img", { src: originImg, className: 'w-[32px]', alt: initialCountryName }), _jsxs("div", __assign({ className: 'text-[14px] items-center ml-1' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: initialCountryCode })), _jsx("div", { children: initialCountryName })] }))] })), _jsx("div", __assign({ className: 'flex-grow mx-2' }, { children: _jsxs("div", __assign({ className: 'flex justify-between border-b themes-border-extra-red-v4' }, { children: [_jsxs("div", __assign({ className: 'text-[12px] themes-text-gray-v6' }, { children: [_jsx("div", { children: "ETD" }), _jsx("div", { children: ETD })] })), _jsxs("div", __assign({ className: 'text-[12px] themes-text-gray-v6' }, { children: [_jsx("div", { children: "ETA" }), _jsx("div", { children: ETA })] }))] })) })), _jsxs("div", __assign({ className: 'flex items-center', style: { lineHeight: '14px' } }, { children: [iconMap && _jsx("i", { className: "ri-map-pin-line themes-text-brand-v5 mr-1" }), _jsx("img", { src: destinationImg, className: 'w-[32px]', alt: destinationCountryName }), _jsxs("div", __assign({ className: 'text-[14px] items-center ml-1' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: destinationCountryCode })), _jsx("div", { children: destinationCountryName })] }))] }))] })));
};
export default EstimationInfo;
