var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from 'react';
import { APIProvider, Map, useMap, AdvancedMarker, Pin, } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import './style.css';
var API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
var MAP_ID = process.env.REACT_APP_MAP_ID;
var GoogleMap = function (_a) {
    var points = _a.points;
    return (_jsx(APIProvider, __assign({ apiKey: API_KEY }, { children: _jsx(Map, __assign({ mapId: MAP_ID, defaultCenter: { lat: -20.789275, lng: 113.921327 }, defaultZoom: 2, gestureHandling: 'greedy', disableDefaultUI: true }, { children: points ? _jsx(Markers, { points: points }) : '' })) })));
};
var Markers = function (_a) {
    var points = _a.points;
    var map = useMap();
    var _b = useState({}), markers = _b[0], setMarkers = _b[1];
    var clusterer = useRef(null);
    // Initialize MarkerClusterer
    useEffect(function () {
        if (!map)
            return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map: map });
        }
    }, [map]);
    // Update markers
    useEffect(function () {
        var _a, _b;
        (_a = clusterer.current) === null || _a === void 0 ? void 0 : _a.clearMarkers();
        (_b = clusterer.current) === null || _b === void 0 ? void 0 : _b.addMarkers(Object.values(markers));
    }, [markers]);
    var setMarkerRef = function (marker, key) {
        if (marker && markers[key])
            return;
        if (!marker && !markers[key])
            return;
        setMarkers(function (prev) {
            var _a;
            if (marker) {
                return __assign(__assign({}, prev), (_a = {}, _a[key] = marker, _a));
            }
            else {
                var newMarkers = __assign({}, prev);
                delete newMarkers[key];
                return newMarkers;
            }
        });
    };
    var getTypesLocation = function (myLat, myLng, myApiKey) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' +
                        myLat +
                        ',' +
                        myLng +
                        '&key=' +
                        myApiKey)
                        .then(function (response) { return response.json(); })
                        .then(function (responseJson) {
                        console.log('ADDRESS GEOCODE is BACK!! => ' +
                            JSON.stringify(responseJson));
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: points && points.map(function (point) {
            return (_jsxs(AdvancedMarker, __assign({ position: point, ref: function (marker) { return setMarkerRef(marker, point.key); } }, { children: [_jsx(Pin, { background: point.status ? "#0058FF" : "#8D99A5", borderColor: "#EEF3FA", glyphColor: "#EEF3FA" }), _jsx("span", { className: "tree" })] }), point.key));
        }) }));
};
export default GoogleMap;
