/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
export function useTable(_a) {
    var data = _a.data, tabFilterItem = _a.tabFilterItem;
    // initial
    var _b = useState([]), generatedData = _b[0], setGeneratedData = _b[1];
    // useEffect ---------------------------------------------------------------
    useEffect(function () {
        if (!tabFilterItem) {
            return;
        }
        setGeneratedData(generateData(generatedData));
    }, []);
    useEffect(function () {
        setGeneratedData(generateData(data));
    }, [data]);
    // End useEffect -----------------------------------------------------------
    // additional function -----------------------------------------------------
    function generateData(dt) {
        if ((typeof dt === 'object' && !Array.isArray(dt)) || !dt) {
            return [];
        }
        var dt_ = dt;
        if (tabFilterItem) {
            if (tabFilterItem.value === 'all') {
                dt_ = data.filter(function (d) {
                    return d;
                });
            }
            else {
                dt_ = data.filter(function (d) {
                    if (d[tabFilterItem.key] === tabFilterItem.value) {
                        return d;
                    }
                });
            }
        }
        return dt_;
    }
    // end function -------------------------------------------------
    return {
        generatedData: generatedData,
    };
}
