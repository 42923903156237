import { faker } from "@faker-js/faker"
import { IInfoList, ITabsConfig } from "./performances.interface"

export const optionsFilterPerformances = [
    {
        label: 'Displayed Information',
        value: 'displayed',
    },
    {
        label: 'Filtered By',
        value: 'filtered',
    },
    {
        label: 'Duration',
        value: 'totalVolume',
    },
    {
        label: 'Total Weight',
        value: 'weight',
    },
]


export const itemListInfoJoinChart = [
    {
        label: '2022',
        color: 'red-v2',
    },
    {
        label: '2023',
        color: 'yellow-v2',
    },
    {
        label: '2024',
        color: 'green-v3',
    },
]


export const itemInfoBarChart: IInfoList[] = [
    {
        label: 'Actual Transit time',
        labelTranslation: 'performance.actual_transit_time',
        color: 'brand-v4',
    },
    {
        label: 'Planned Transit Time',
        labelTranslation: 'performance.planned_transit_time',
        color: 'brand-v3',
    },
    {
        label: 'Number of Shipments',
        labelTranslation: 'performance.number_of_shipments',
        color: 'yellow-v2',
    },
]

export const itemInfoBarChartCoast: IInfoList[] = [
    {
        label: 'Air',
        labelTranslation: 'performance.air',
        color: 'violet-v2',
    },
    {
        label: 'Ocean - FCL',
        labelTranslation: 'performance.ocean_fcl',
        color: 'blue-v4',
    },
    {
        label: 'Ocean - LCL',
        labelTranslation: 'performance.ocean_lcl',
        color: 'blue-v2',
    },
]

export const itemInfoTransit: IInfoList[] = [
    {
        label: 'CRD to pickup',
        labelTranslation: 'performance.crd_to_pickup',
        color: 'yellow-v2',
    },
    {
        label: 'Pickup to departure port',
        labelTranslation: 'performance.pickup_to_departure_port',
        color: 'yellow-v3',
    },
    {
        label: 'Departure port dwell',
        labelTranslation: 'performance.departure_port_dwell',
        color: 'brand-v3',
    },
    {
        label: 'Departure port to arrival port',
        labelTranslation: 'performance.departure_port_to_arrival_port',
        color: 'brand-v4',
    },
    {
        label: 'Arrival port dwell',
        labelTranslation: 'performance.arrival_port_dwell',
        color: 'green-v2',
    },
    {
        label: 'Arrival port to delivery',
        labelTranslation: 'performance.arrival_port_to_delivery',
        color: 'green-v1',
    },
]

export const itemListInfoBarChart = [
    {
        label: 'On Time',
        color: 'yellow-v1',
    },
    {
        label: '1-2 Days',
        color: 'yellow-v2',
    },
    {
        label: '4-7 Days',
        color: 'brand-v3',
    },
    {
        label: '7-14 Days',
        color: 'brand-v4',
    },
    {
        label: '14 Days+',
        color: 'green-v4',
    },
]

export const itemListInfoPortMetics: IInfoList[] = [
    {
        label: 'Detentions POL',
        labelTranslation: 'performance.detentions_pol',
        color: 'gray-v3',
    },
    {
        label: 'Days at POL',
        labelTranslation: 'performance.days_at_pol',
        color: 'brand-v3',
    },
    {
        label: 'Time on Board',
        labelTranslation: 'performance.time_on_board',
        color: 'brand-v4',
    },
    {
        label: 'Transit Time P2P',
        labelTranslation: 'performance.transit_time',
        color: 'brand-v5',
    },
    {
        label: 'Time on Board POD',
        labelTranslation: 'performance.time_on_board_pod',
        color: 'gray-v8',
    },
    {
        label: 'Dayas at POD',
        labelTranslation: 'performance.days_at_pod',
        color: 'gray-v6',
    },
    {
        label: 'Detention POD',
        labelTranslation: 'performance.detention_pod',
        color: 'gray-v4',
    },
]

export const ShipmentMode = [
    {
        toolTipName: 'Eastern Asia - Western Europe',
        name: 'Eastern Asia - Western Europe',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Eastern Asia - Scutheast Asia',
        name: 'Eastern Asia - Scutheast Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Eastern Asia',
        name: 'Western Europe - Eastern Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Eastern America',
        name: 'Western Europe - Eastern America',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Asia',
        name: 'Western Europe - Western Asia',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Europe ',
        name: 'Western Europe - Western Europe ',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        toolTipName: 'Western Europe - Western Africa ',
        name: 'Western Europe - Western Africa ',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]


export const dataTransitTime = {
    barColor1: "#266EDC",
    barColor2: "#8BB8FF",
    item: [
        {
            name: 'Ningbo - Le Havre',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'Le Havre - New York',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'Le Havre - Singapore',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'Ningbo - Dunker',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'Le Havre - Laem Chabang',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'Le Havre - Port Klang',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        }
    ]
};

export const tabsConfig: ITabsConfig[] = [
    { id: 'performance', label: 'performance.performance' },
    { id: 'transit', label: 'performance.transit_time' },
    { id: 'coasts', label: 'performance.coasts' }
];

export const tabsConfigTotalCoast: ITabsConfig[] = [
    { id: 'routes', label: 'performance.routes' },
    { id: 'origin', label: 'performance.origin' },
    { id: 'destination', label: 'performance.destination' }
];

export const tabsConfigTransit: ITabsConfig[] = [
    { id: 'doorToDoor', label: 'performance.door_to_door' },
    { id: 'deferred', label: 'performance.deferred' },
    { id: 'direct', label: 'performance.direct' },
    { id: 'standard', label: 'performance.standard' },
    { id: 'transhipment', label: 'performance.transhipment' }
];

export const dataJointChart = {
    lineColor1: '#f04770',
    key: [
        {
            id: "line1",
            color: "#f04770"
        },
        {
            id: "line2",
            color: "#ffe3a4"
        },
        {
            id: "line3",
            color: "#10d3a0"
        },
    ],
    line1: [
        { x: 1, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 2, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 3, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 4, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 5, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 6, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 7, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 8, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 9, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 10, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 11, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 12, y: faker.number.int({ min: 0, max: 10 }) },
    ],
    lineColor2: '#ffe3a4',
    line2: [
        { x: 1, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 2, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 3, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 4, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 5, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 6, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 7, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 8, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 9, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 10, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 11, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 12, y: faker.number.int({ min: 0, max: 10 }) },
    ],
    lineColor3: '#10d3a0',
    line3: [
        { x: 8, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 9, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 10, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 11, y: faker.number.int({ min: 0, max: 10 }) },
        { x: 12, y: faker.number.int({ min: 0, max: 10 }) },
    ],
};

export const dataBarChart = {
    key: [
        {
            id: "f1",
            color: "#048160"
        },
        {
            id: "f2",
            color: "#266edc"
        },
        {
            id: "f3",
            color: "#8bb8ff"
        },
        {
            id: "f4",
            color: "#ffe3a4"
        },
        {
            id: "f5",
            color: "#fff6e1"
        }
    ],
    data: [
        {
            name: 'Ningbo',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Havre',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Qing Dao',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Ningbo',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Havre',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Qing Dao',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Ningbo',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Havre',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Qing Dao',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Ningbo',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Le Havre',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            f4: faker.number.int({ min: 2, max: 5 }),
            f5: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
    ]
}


export const dataBarChartTotalCoast = {
    key: [
        {
            id: "f1",
            color: "#06CAD7"
        },
        {
            id: "f2",
            color: "#266EDC"
        },
        {
            id: "f3",
            color: "#A647F0"
        },
    ],
    data: [
        {
            name: 'Jan',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Feb',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Mar',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Apr',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'May',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Jun',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Jul',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Aug',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Sep',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Oct',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Nov',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
        {
            name: 'Des',
            f1: faker.number.int({ min: 2, max: 5 }),
            f2: faker.number.int({ min: 2, max: 5 }),
            f3: faker.number.int({ min: 2, max: 5 }),
            amt: 10,
        },
    ]
}

export const dataSimpleLine = [
    { name: 'Jan 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2400 },
    { name: 'Feb 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2210 },
    { name: 'Mar 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2290 },
    { name: 'Apr 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2000 },
    { name: 'May 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2181 },
    { name: 'Jun 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2500 },
    { name: 'Jul 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Aug 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Sep 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Oct 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Nov 2023', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Dec 2024', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Jan 2024', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Feb 2024', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Mar 2024', pv: faker.number.int({ min: 30, max: 100 }), amt: 2100 },
    { name: 'Apr 2024', pv: faker.number.int({ min: 2, max: 2 }), amt: 2100 },
    { name: 'May 2024', pv: faker.number.int({ min: 2, max: 2 }), amt: 2100 },
    { name: 'Jun 2024', pv: faker.number.int({ min: 2, max: 2 }), amt: 2100 },
    { name: 'Jul 2024', pv: faker.number.int({ min: 2, max: 2 }), amt: 2100 },
]
