var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
var BarChartStacked = function (_a) {
    var data = _a.data, _b = _a.useLabel, useLabel = _b === void 0 ? false : _b;
    var renderCustomizedLabel = useCallback(function (props) {
        var x = props.x, y = props.y, width = props.width, value = props.value, index = props.index, height = props.height;
        var dataItem = data.data[index];
        var totalF = data.key.reduce(function (sum, keyItem) {
            return sum + dataItem[keyItem.id];
        }, 0);
        var percentage = ((value / totalF) * 100).toFixed(2);
        return (_jsxs("text", __assign({ x: x + width / 2, y: y + height / 2, fill: "#465A6C", textAnchor: "middle", fontSize: 12, fontWeight: "bold" }, { children: [percentage, "%"] })));
    }, [data]);
    var RoundedTopBar = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height, fill = props.fill, index = props.index;
        var isTopBar = index === data.key.length - 1;
        return (_jsxs("g", { children: [_jsx("rect", { x: x, y: y, width: width, height: height, fill: fill }), isTopBar && (_jsx("rect", { x: x, y: y, width: width, height: 10, fill: fill, rx: 10, ry: 10 }))] }));
    };
    return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ width: 500, height: 300, data: data.data, margin: {
                top: 0,
                right: 0,
                left: -20,
                bottom: -5,
            }, barCategoryGap: "10%" }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", tick: { fill: '#666' }, style: { fontSize: '12px' } }), _jsx(YAxis, { tick: { fill: '#666' }, style: { fontSize: '12px' } }), _jsx(Tooltip, {}), data.key.map(function (item, idx) { return (_jsx(Bar, { dataKey: item.id, stackId: "a", fill: item.color, label: useLabel ? renderCustomizedLabel : undefined, shape: _jsx(RoundedTopBar, { index: idx }) }, "item-bar-".concat(idx))); })] })) })));
};
export default BarChartStacked;
