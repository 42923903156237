import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { IOrder } from './orders.interface'
import { OrderStatusHeader, OrderSummaryHeader } from './orders.static'
import { metaDummy, OrderDataDummy } from './orders.dummy'
import useOrder from './orders.service'
import OrderFilterOverlay from './components/orders-filter-overlay.component'
import OrderFilter from './components/orders-filter.component'
import { useTranslation } from 'react-i18next'

const Orders = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        setTabFilter,
        allModal,
        headers,
        ConfigureColumnsModalService,
        navigate,
    } = useOrder()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={OrderStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('orders.docket_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <OrderFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                />

                <Table<IOrder>
                    headers={headers}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={(e) => {
                        navigate(`/orders-details/${e.docketID}`)
                    }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    components={<OrderFilter isGenerateReport={true} />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={OrderDataDummy}
                    GenerateReportHeaders={OrderSummaryHeader}
                    removeFilter={() => {}}
                />
            </div>
            {allModal}
        </div>
    )
}

export default Orders
