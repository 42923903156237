var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logoExport from "@assets/img/logos/IconLogistical.png";
import XLSX from "xlsx-js-style";
import { Toast } from "@components/toast/toast.component";
import moment from "moment";
var useButtonExport = function (_a) {
    var headers = _a.headers, module = _a.module, getDataGenerate = _a.getDataGenerate;
    // varible
    var headerFilter = headers === null || headers === void 0 ? void 0 : headers.filter(function (header) {
        var exportThisColumn = header.exportColumn === false ? false : true;
        if (exportThisColumn) {
            return true;
        }
        return false;
    });
    headerFilter === null || headerFilter === void 0 ? void 0 : headerFilter.filter(function (header) {
        var exportThisColumn = header.exportColumn === false ? false : true;
        if (exportThisColumn) {
            return true;
        }
        return false;
    });
    // function export pdf
    var exportPdf = function () {
        var doc = new jsPDF({
            orientation: "landscape",
            unit: "pt",
        });
        var img = new Image();
        img.src = logoExport;
        autoTable(doc, {
            html: "#table-master",
            theme: "striped",
            head: headerFilter.map(function (header) {
                return header.label;
            }),
            margin: {
                left: 15,
                right: 15,
                bottom: 5,
            },
            headStyles: {
                cellPadding: 5,
                lineWidth: 0,
                valign: "top",
                fontStyle: "bold",
                halign: "left",
                fillColor: [1, 117, 161],
                textColor: [255, 255, 255],
                minCellHeight: 22,
            },
            styles: {
                minCellHeight: 24,
                cellPadding: {
                    top: 8,
                    right: 4,
                    bottom: 8,
                    left: 4,
                },
                fontSize: 8,
            },
            didDrawPage: function () {
                doc.text("".concat(module, " Data"), 15, 30);
                doc.addImage(img, "PNG", 755, 15, 50, 20, "a", "FAST");
            },
        });
        doc.save("Logistical ".concat(module, ".pdf"));
    };
    // function export xlxs
    var exportXlxs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, wb, headerReport, cols1, mapData, rows, ws, nameFile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDataGenerate()];
                case 1:
                    data = _a.sent();
                    if (!data.length) {
                        Toast({
                            header: "Failed",
                            message: "Filtered data is empty",
                            type: "error",
                        });
                        return [2 /*return*/];
                    }
                    wb = XLSX.utils.book_new();
                    headerReport = [];
                    cols1 = [];
                    headers.map(function (x) {
                        headerReport.push({
                            v: x.label,
                            t: "s",
                            s: {
                                fill: { fgColor: { rgb: "00B0F2" } },
                                font: { bold: true, color: { rgb: "FFFFFF" } },
                            },
                        });
                        cols1.push({ wch: x === null || x === void 0 ? void 0 : x.widthCol }); // for width cols
                    });
                    mapData = data === null || data === void 0 ? void 0 : data.map(function (x) {
                        return headers === null || headers === void 0 ? void 0 : headers.map(function (y) { return ({
                            v: y.customBuild
                                ? y === null || y === void 0 ? void 0 : y.customBuild(x[y.accessor] || "")
                                : x[y.accessor] || "",
                            t: "s",
                            s: y === null || y === void 0 ? void 0 : y.styleXlxs,
                        }); });
                    });
                    rows = __spreadArray([headerReport], mapData, true);
                    ws = XLSX.utils.aoa_to_sheet(rows);
                    // Atur lebar kolom dalam worksheet
                    ws["!cols"] = cols1;
                    // Add worksheet to workbook
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                    switch (module) {
                        case "Purchase Order":
                            nameFile = "Purchase_Orders";
                            break;
                        case "Shipment":
                            nameFile = "Shipment";
                            break;
                        default:
                            nameFile = "";
                            break;
                    }
                    // Write Excel file to browser
                    XLSX.writeFile(wb, "Report_".concat(nameFile, "_").concat(moment().format("DD-MM-YYYY_HH-mm-ss"), ".xlsx"));
                    Toast({
                        header: "Sucess",
                        message: "The report was generated successfully",
                        type: "success",
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    // fucntion for redesign generate report
    return { exportPdf: exportPdf, exportXlxs: exportXlxs };
};
export default useButtonExport;
