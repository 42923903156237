import Button from '@components//button/button.component'
import Overlay from '@components//overlay/overlay.component'
import { IUseOverlay } from '@components//overlay/overlay.service'
import { useNavigate } from 'react-router-dom'
import '../shipments.style.css'

const NewBookingOverlay = ({
    overlayService,
}: {
    overlayService: IUseOverlay
}) => {
    const navigate = useNavigate()
    return (
        <Overlay
            isOverlayOpen={overlayService.isOverlayOpen}
            className="new-booking flex overflow-hidden"
        >
            <div className={`w-full`}>
                <div className="flex font-bold text-size-L justify-between items-center px-5 py-3">
                    New Booking
                    <Button
                        className="!w-4 !h-4 !border-0"
                        icon="ri-close-fill"
                        onClick={() => {
                            overlayService.closeOverlayHandling()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <div className="w-full flex flex-col gap-4 px-5 py-3">
                    <span className="text-size-M font-bold">
                        Book by Packing Type:{' '}
                    </span>
                    <ul className="list-none">
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4 text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Loose
                        </li>
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4 text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Full Container Load
                        </li>
                        <li
                            className="themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Full Truck Load
                        </li>
                    </ul>
                    <span>Book by Mode: </span>
                    <ul className="list-none">
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Air
                        </li>
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Sea
                        </li>
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Road
                        </li>
                        <li
                            className="border-b border-gray-40 themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Rail
                        </li>
                        <li
                            className="themes-bg-hover-brand-v4  text-size-M transition py-1 cursor-pointer rounded-[5px] px-2"
                            onClick={() => {
                                navigate('/create-booking')
                            }}
                        >
                            Courier
                        </li>
                    </ul>
                </div>
            </div>
        </Overlay>
    )
}

export default NewBookingOverlay
