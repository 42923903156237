import { faker } from '@faker-js/faker'
import { IshipmentTrackingHeader, ILineChartItem, IHeaderLineChart } from '../dashboard.interface'

export const totalShipmentMode: ILineChartItem[] = [
    {
        name: 'dashboard.mon',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.tue',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.wed',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.thu',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.fri',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.sat',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.nav',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]

export const totalArrival = [
    {
        name: 'Jan',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Feb',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Mar',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Apr',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'May',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Jun',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Jul',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Aug',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Sep',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Oct',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Nov',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
    {
        name: 'Dec',
        ontime: faker.number.int({ min: 20001, max: 50000 }),
        delayed: faker.number.int({ min: 0, max: 20000 }),
    },
]

export const totalVolumeByMode: IHeaderLineChart[] = [
    {
        mode: 'dashboard.sea_fcl',
        detail: '10 TEU',
    },
    {
        mode: 'dashboard.sea_lcl',
        detail: '17 CBM',
    },
    {
        mode: 'dashboard.rail_fcl',
        detail: '3 FEU',
    },
    {
        mode: 'dashboard.rail_lcl',
        detail: '4 CBM',
    },
    {
        mode: 'dashboard.air',
        detail: '6,7 TONS',
    },
    {
        mode: 'dashboard.road_ltl',
        detail: '3',
    },
]

export const arrivalDetail = [
    {
        mode: 'Arrival Accuracy',
        detail1: '91%',
        detail2: '9%',
        increase2: false,
    },
    {
        mode: 'On Time Arrival',
        detail1: '3280',
        detail2: '+12',
        increase2: true,
    },
    {
        mode: 'Delayed Arrival',
        detail1: '10',
        detail2: '+2',
        increase2: true,
    },
]

export const news = [
    {
        id: '1',
        title: '35-Hour Rail Strike Announced in Germany',
        url: ''
    },
    {
        id: '2',
        title: 'Update: Red Sea & The Freight Market',
        url: ''
    },
    {
        id: '3',
        title: 'Lunar New Year IFB Office Closings',
        url: ''
    },
    {
        id: '4',
        title: 'IFB Poland X Polish Amp Football National Team',
        url: ''
    },
    {
        id: '5',
        title: 'The Situation in the Red Sea and Lunar New Year',
        url: ''
    },
    {
        id: '6',
        title: 'New Office Location in Guangzhou',
        url: ''
    }
]

export const shipmentTrackingHeader: IshipmentTrackingHeader[] = [
    {
        value: 'mode',
        label: 'dashboard.mode',
    },
    {
        value: 'id',
        label: 'dashboard.no',
    },
    {
        value: 'etd',
        label: 'dashboard.etd',
    },
    {
        value: 'eta',
        label: 'dashboard.eta',
    },
    {
        value: 'origin',
        label: 'dashboard.origin',
    },
    {
        value: 'destination',
        label: 'dashboard.destination',
    },
]

// tgl-bulan-thn
export function getShipmentTracking(): any {
    let data = []

    for (let i = 0; i < 2; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `THBKK`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 3; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `ID`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 10; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `THBKK`,
            destination: `AUMEL`,
        })
    }
    for (let i = 0; i < 10; i++) {
        data.push({
            mode: `${faker.helpers.arrayElement(['ri-ship-line', 'ri-plane-line', 'ri-truck-line'])}`,
            id: `S0000${faker.number.int({ min: 2000, max: 5000 })}`,
            etd: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 1, max: 3 })}/24`,
            eta: `${faker.number.int({ min: 1, max: 29 })}/${faker.number.int({ min: 4, max: 7 })}/24`,
            origin: `ID`,
            destination: `AUMEL`,
        })
    }
    return data
}
