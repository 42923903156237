var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export function TableCardRow(_a) {
    var props = __rest(_a, []);
    var IconRow = props.data.typeTransport === 'SEA' ? ' ri-ship-line' : props.data.typeTransport === 'AIR' ? 'ri-plane-line' : 'ri-truck-line';
    var total = props.data.mediaTransit.reduce(function (sum, item) { return sum + item.value; }, 0);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'grid grid-cols-12 justify-between  mb-[10px] px-[16px] py-[10px] border-[1px] rounded-md' }, { children: [_jsx("div", __assign({ className: 'col-span-2 flex' }, { children: _jsxs("div", __assign({ className: 'flex item-center' }, { children: [_jsx("i", { className: "".concat(IconRow, " text-[28px] themes-text-brand-v5") }), _jsxs("div", __assign({ className: 'ml-5' }, { children: [_jsx("div", __assign({ className: 'text-[12px] themes-text-gray-v5' }, { children: "Departure Port" })), _jsxs("div", __assign({ className: 'text-[16px] font-bold' }, { children: [" ", props.data.description] }))] }))] })) })), _jsx("div", __assign({ className: 'col-span-2 flex' }, { children: _jsxs("div", __assign({ className: 'flex item-center flex-col' }, { children: [_jsx("div", __assign({ className: 'text-[12px] themes-text-gray-v5' }, { children: "Arival Port" })), _jsxs("div", __assign({ className: 'text-[16px] font-bold' }, { children: [" ", props.data.arivalPort] }))] })) })), _jsxs("div", __assign({ className: 'col-span-8 flex ' }, { children: [_jsxs("div", __assign({ className: 'flex item-center flex-col' }, { children: [_jsx("div", __assign({ className: 'text-[12px] themes-text-gray-v5' }, { children: "Total Shipment" })), _jsxs("div", __assign({ className: 'text-[16px] font-bold' }, { children: [" ", props.data.totalShipment] }))] })), _jsx("div", __assign({ className: 'flex-grow flex flex-col item-start h-full' }, { children: _jsxs("div", __assign({ className: "flex-grow mx-5" }, { children: [_jsx("div", __assign({ className: 'text-[12px] themes-text-gray-v5' }, { children: "Median Transit Time" })), _jsx("div", __assign({ className: "relative bg-gray-300 h-4 flex rounded-none" }, { children: props.data.mediaTransit.map(function (item, index) {
                                            var percentage = (item.value / total) * 100;
                                            return (_jsx("div", { className: "relative h-full themes-bg-".concat(item.color), style: { width: "".concat(percentage, "%") }, title: "Item ".concat(index + 1, ": ").concat(percentage.toFixed(2), "%") }, index));
                                        }) }))] })) })), _jsxs("div", __assign({ className: 'flex item-center flex-col' }, { children: [_jsx("div", __assign({ className: 'text-[12px] themes-text-gray-v5' }, { children: "Days" })), _jsxs("div", __assign({ className: 'text-[16px] font-bold' }, { children: [" ", total] }))] }))] }))] })) }));
}
