import { faker } from '@faker-js/faker'
import { IHeaderLineChart, ILineChartItem, IOpenInvoice } from '../dashboard.interface'

export const paymentInvoiceCompletion: ILineChartItem[] = [
    {
        name: 'dashboard.mon',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.tue',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.wed',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.thu',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.fri',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.sat',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
    {
        name: 'dashboard.nav',
        number: faker.number.int({ min: 20001, max: 49000 }),
    },
]

export const shipmentOrderAnalytics = [
    {
        name: 'Jan',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Feb',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Mar',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Apr',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'May',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Jun',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Jul',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Aug',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Sep',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Oct',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Nov',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
    {
        name: 'Dec',
        'In Progress': faker.number.int({ min: 20001, max: 49000 }),
        Completed: faker.number.int({ min: 0, max: 30000 }),
    },
]

export const paymentInvoiceDetail: IHeaderLineChart[] = [
    {
        mode: 'dashboard.avg_per_month',
        detail: 'US$ 38,585',
    },
    {
        mode: 'dashboard.total_costs_overtime',
        detail: 'US$ 100,585',
    },
]

export const openInvoice: IOpenInvoice[] = [
    {
        name: 'dashboard.1_within_payment_term',
        value: 18906,
        percentage: 33.15,
        fill: '#CDF7EC',
        textColor: '#048160',
    },
    {
        name: 'dashboard.1_week_overdue',
        value: 8806,
        percentage: 24.49,
        fill: '#FFF6E1',
        textColor: '#997D3E',
    },
    {
        name: 'dashboard.>1_week_overdue',
        value: 4643,
        percentage: 17.15,
        fill: '#FCDAE2',
        textColor: '#902B43',
    },
]
