import App from 'App'
import { IRoute } from 'common/common.interface'
import { getRoutesByClient } from 'common/common.service'
import { IConfig } from 'config/config.interface'
import { useConfig } from 'config/config.service'
import { configSelector } from 'config/config.slice'
import Page404 from 'client/common/pages/404-page/404-page.component'
import Login from 'client/common/pages/login/login.component'
import ResetPasswordPage from 'client/common/pages/reset-password/reset-password-page.component'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

const AppRoute: React.FC = () => {
    useConfig()
    const config: IConfig = useSelector(configSelector)

    const [routes, setRoutes] = useState<IRoute[]>([])
    useEffect(() => {
        if (config.client === '') setRoutes([])
        setRoutes(getRoutesByClient())
    }, [config])

    return (
        <Routes>
            {/* Route Before Logun */}
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ResetPasswordPage />} />

            {/* Route After Login */}
            <Route element={<App />}>
                {routes.map(
                    (
                        { Content, path, expandable, sub }: IRoute,
                        idx: number,
                    ) => {
                        if (!expandable) {
                            return (
                                <Route
                                    key={idx}
                                    path={path}
                                    Component={Content}
                                />
                            )
                        } else {
                            return sub?.map((item, idxx) => {
                                return (
                                    <Route
                                        key={idxx}
                                        path={item.path}
                                        Component={item.Content}
                                    />
                                )
                            })
                        }
                    },
                )}

                {/* 404 */}
                {config.client === '' || routes.length < 1 ? (
                    <Route path="/*" element={<></>} />
                ) : (
                    <Route path="/*" element={<Page404 />} />
                )}
            </Route>
        </Routes>
    )
}

export default AppRoute
