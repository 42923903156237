import Dropdown from '@components//dropdown/dropdown.component'
import Input from '@components//input/input.component'
import SimpleTable from '@components//simple-table/simple-table.component'
import { PackLinesHeader } from '../create-booking.static'

const GoodsDetails = ({
    actionHandling,
}: {
    actionHandling: () => JSX.Element
}) => {
    return (
        <div className="flex flex-col w-full p-4 space-y-4 h-[calc(100vh-280px)] overflow-y-auto">
            <div className="flex-col w-full space-y-4">
                <div className="flex w-full">
                    <span className="text-size-L font-bold">Shipper</span>
                </div>
                <div className="flex w-full space-x-[10px]">
                    <div className="flex w-1/2">
                        <Input
                            label="SHIPPER'S REF"
                            placeholder="Input Shipper's Ref"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'SERVICE LEVEL'}
                            options={[
                                {
                                    label: 'Standard',
                                    value: 'Standard',
                                },
                            ]}
                            placeholder="Select Service Level"
                        />
                    </div>
                </div>
                <div className="flex w-full space-x-[10px]">
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'INCOTERM'}
                            options={[
                                {
                                    label: 'FREE ON BOARD',
                                    value: 'FREE ON BOARD',
                                },
                            ]}
                            placeholder="Select Incoterm"
                            className="flex w-1/2"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Input
                            label="ADDITIONAL TERMS"
                            placeholder="Input Additional Terms"
                            className="flex w-1/2"
                        />
                    </div>
                </div>
                <div className="flex w-full space-x-[10px]">
                    <Input
                        label="GOODS DESCRIPTION"
                        placeholder="Input Goods Description"
                    />
                </div>
                <div className="flex w-full space-x-[10px]">
                    <Input
                        label="MARKS & NUMBERS"
                        placeholder="Input Marks & Numbers"
                    />
                </div>
                <div className="flex w-full space-x-[10px]">
                    <div className="flex w-1/2">
                        <Input
                            label="GOODS VALUE"
                            placeholder="Input Goods Value"
                            className="flex w-1/4"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'GOODS VALUE CURRENCY'}
                            options={[
                                {
                                    label: 'USD',
                                    value: 'USD',
                                },
                            ]}
                            placeholder="Select Goods Value Currency"
                            className="flex w-1/4"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Input
                            label="INSURANCE VALUE"
                            placeholder="Input Insurance Value"
                            className="flex w-1/4"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'INSURANCE VALUE CURRENCY'}
                            options={[
                                {
                                    label: 'USD',
                                    value: 'USD',
                                },
                            ]}
                            placeholder="Select Insurance Value Currency"
                            className="flex w-1/4"
                        />
                    </div>
                </div>
                <div className="flex w-full space-x-[10px]">
                    <div className="flex w-1/2">
                        <Input label="PACKS" placeholder="Input Packs" />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'OUTER PACKS UNIT'}
                            options={[
                                {
                                    label: 'Pallet',
                                    value: 'Pallet',
                                },
                            ]}
                            placeholder="Select Outer Packs Unit"
                        />
                    </div>
                </div>
                <div className="flex w-full space-x-[10px]">
                    <div className="flex w-1/2">
                        <Input
                            label="VOLUME MEASURE"
                            placeholder="Input Volume Measure"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'VOLUME UOM'}
                            options={[
                                {
                                    label: 'M3',
                                    value: 'M3',
                                },
                            ]}
                            placeholder="Select Volume UOM"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Input
                            label="WEIGHT MEASURE"
                            placeholder="Input Weight Measure"
                        />
                    </div>
                    <div className="flex w-1/2">
                        <Dropdown
                            label={'WEIGHT UOM'}
                            options={[
                                {
                                    label: 'KG',
                                    value: 'KG',
                                },
                            ]}
                            placeholder="Select Weight UOM"
                        />
                    </div>
                </div>
            </div>
            <div className="flex-col w-full space-y-4">
                <div className="flex w-full">
                    <span className="text-size-L font-bold">Pack Lines</span>
                </div>
                <div className="flex w-full gap-4">
                    <SimpleTable
                        headers={PackLinesHeader}
                        data={[]}
                        customBuildParam2={actionHandling}
                        isAddRow={true}
                        addRowLabel="Add new Pack Line"
                    />
                </div>
            </div>
        </div>
    )
}

export default GoodsDetails
