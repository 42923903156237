import { useState } from 'react'
import Dropdown from '@components/dropdown/dropdown.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import '../style.css'
import Header from './header.component'
import { optionDates, optionDisplay } from '../dashboard.dummy'
import { IDashboardItem } from '../row1/row1.dummy'
import { useTranslation } from 'react-i18next'

interface ProgressProps {
    data: IDashboardItem
}

function Progress({ data }: ProgressProps) {
    const { t } = useTranslation()
    const { title, total, summaryTotal, detail, isIncrease } = data

    const [isFilter, setIsFilter] = useState<boolean>(false)

    const [dateOption, setDateOption] = useState<
        IDropdownItem<unknown> | undefined
    >({
        value: '7D',
        label: t('dashboard.last_days', {
            value: 7,
        }),
    })
    const [displayOption, setDisplayOption] = useState<
        IDropdownItem<unknown> | undefined
    >()

    const getSubtitle = (module: string) => {
        switch (module) {
            case 'dashboard.bookings_sea':
                return [
                    t('dashboard.booked'),
                    t('dashboard.confirmed'),
                    t('dashboard.cancelled'),
                ]
            case 'dashboard.shipment_status':
                return [
                    t('dashboard.ahead_of_schedule'),
                    t('dashboard.on_schedule'),
                    t('dashboard.delayed'),
                ]
            case 'dashboard.schedule_changes':
                return [
                    t('dashboard.rollover_vessel'),
                    t('dashboard.routing_change'),
                    t('dashboard.other_changes'),
                ]
            case 'dashboard.warehouse':
                return [
                    t('dashboard.in_stock'),
                    t('dashboard.low_stock'),
                    t('dashboard.out_of_stock'),
                ]
            case 'dashboard.c02e_emission':
                return [
                    t('dashboard.sea'),
                    t('dashboard.flight'),
                    t('dashboard.road'),
                ]
            case 'dashboard.scfi_index':
                return [t('dashboard.scfi_desc')]
        }
    }

    return (
        <div className="grid grid-rows-1 gap-0  h-[100%]">
            {/* Title */}
            <Header
                className="row-span-2"
                title={t(title)}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                filter={t('dashboard.last_days', {
                    value: 7,
                })}
            />
            {isFilter ? (
                <div
                    className={`grid 2xl:row-span-12 2xl:gap-[4px] md:row-span-6 md:gap-[4px] sm:row-span-6 sm:gap-[5px]`}
                >
                    <Dropdown
                        options={optionDates}
                        onClick={(value) => {
                            setDateOption(value)
                        }}
                        className="customDropdown"
                        useBorder={true}
                        value={dateOption}
                    />
                    <Dropdown
                        options={optionDisplay}
                        onClick={(value) => {
                            setDisplayOption(value)
                        }}
                        className="customDropdown"
                        placeholder="Displayed details"
                        useBorder={true}
                        value={displayOption}
                    />
                </div>
            ) : (
                <>
                    {/* Summary */}
                    <div className={`grid row-span-3 transition-opacity`}>
                        <div className="items-center gap-[12px] inline-flex">
                            <div className="text-gray-700 lg:text-[20px] md:text-[32px] font-bold font-['Bahnschrift']">
                                {total}
                            </div>
                            <div
                                className={`p-[5px] self-center ${isIncrease ? 'bg-green-100' : 'bg-red-100'} px-1.5 rounded flex-col justify-center items-center gap-2.5 inline-flex`}
                            >
                                <div className="justify-start items-center inline-flex">
                                    <div className="text-zinc-600 lg:text-[14px] md:text-[20px] font-bold font-['Basis Grotesque Arabic Pro'] leading-[18px]">
                                        {summaryTotal}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Detail Summary */}
                    <div
                        className={`grid row-span-5 grid-cols-8 grid-rows-3 text-gray-500 lg:text-[12px] md:text-xs font-['Bahnschrift']`}
                    >
                        {title !== 'dashboard.scfi_index' ? (
                            <>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[0]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail1}
                                </div>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[1]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail2}
                                </div>
                                <div className="col-span-6">
                                    {getSubtitle(title)?.[2]}
                                </div>
                                <div className="col-span-2 justify-self-end">
                                    {detail?.detail3}
                                </div>
                            </>
                        ) : (
                            <div className="col-span-8 row-span-3 line-clamp-3">
                                {getSubtitle(title)?.[0]}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default Progress
