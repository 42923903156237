import SimpleTable from '@components/simple-table/simple-table.component'
import { IBookingRelatedOrder } from '../booking-details.interface'
import { bookingRelatedOrderHeaders } from '../booking-details.static'
import { bookingRelatedOrderDummy, metaDummy } from '../booking-details.dummy'

const RelatedOrders = () => {
    return (
        <SimpleTable<IBookingRelatedOrder>
            headers={bookingRelatedOrderHeaders}
            data={bookingRelatedOrderDummy}
            usePagination
            nextHandling={(page) => {
                console.log('hit Next API -> ' + page)
            }}
            previousHandling={(page) => {
                console.log('hit Prev API -> ' + page)
            }}
            meta={metaDummy}
        />
    )
}

export default RelatedOrders
