import LogisticsEntitiesForm from '../../components/logistics-entities-form.component'
import OriginForm from '../../components/origin-form.component'
import DestinationForm from '../../components/destination-form.component'
import BookingInformation from '../../components/booking-information.component'

const Details = () => {
    return (
        <div className="grid grid-cols-4 gap-3 m-3 h-full">
            <div className="flex flex-col gap-3">
                <div>Shipper</div>
                <LogisticsEntitiesForm
                    entity="consignorPickupAddress"
                    companyOptions={[
                        {
                            label: 'OneByOne Logistical PTY LTD',
                            value: 'OneByOne Logistical PTY LTD',
                        },
                    ]}
                    contactNameOptions={[
                        {
                            label: 'Aimar',
                            value: 'Aimar',
                        },
                    ]}
                />
                <OriginForm />
            </div>
            <div className="flex flex-col gap-3">
                <div>Consignee</div>
                <LogisticsEntitiesForm
                    entity="consigneePickupAddress"
                    companyOptions={[
                        {
                            label: 'ABC Company',
                            value: 'ABC Company',
                        },
                    ]}
                    contactNameOptions={[
                        {
                            label: 'Noelle',
                            value: 'Noelle',
                        },
                    ]}
                />
                <DestinationForm />
            </div>
            <div className="flex flex-col gap-3 col-span-2">
                <BookingInformation />
            </div>
        </div>
    )
}

export default Details
