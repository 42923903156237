import { IMeta } from 'common/common.interface'
import { IOrder } from './orders.interface'

export const OrderDataDummy: IOrder[] = [
    {
        docketID: 'DK-001',
        warehouseName: 'HarborView Warehouse',
        externalReference: 'ER-001',
        orderStatus: 'Pending',
        requiredDate: '03-05-2024',
        finalisedDate: '17-05-2024',
        totalUnits: '100 units',
    },
    {
        docketID: 'DK-002',
        warehouseName: 'Summit Storage Solutions',
        externalReference: 'ER-002',
        orderStatus: 'Processing',
        requiredDate: '15-06-2024',
        finalisedDate: '29-06-2024',
        totalUnits: '250 units',
    },
    {
        docketID: 'DK-003',
        warehouseName: 'Riverside Logistics Center',
        externalReference: 'ER-003',
        orderStatus: 'Shipped',
        requiredDate: '21-07-2024',
        finalisedDate: '05-08-2024',
        totalUnits: '500 units',
    },
    {
        docketID: 'DK-004',
        warehouseName: 'MetroWare Warehouse',
        externalReference: 'ER-004',
        orderStatus: 'Delivered',
        requiredDate: '09-08-2024',
        finalisedDate: '24-09-2024',
        totalUnits: '75 units',
    },
    {
        docketID: 'DK-005',
        warehouseName: 'Valley Vista Warehouse',
        externalReference: 'ER-005',
        orderStatus: 'Cancelled',
        requiredDate: '28-09-2024',
        finalisedDate: '13-11-2024',
        totalUnits: '200 units',
    },
    {
        docketID: 'DK-006',
        warehouseName: 'Coastal Cargo Warehouse',
        externalReference: 'ER-006',
        orderStatus: 'On Hold',
        requiredDate: '10-10-2024',
        finalisedDate: '25-11-2024',
        totalUnits: '150 units',
    },
    {
        docketID: 'DK-007',
        warehouseName: 'Prime Storage Facility',
        externalReference: 'ER-007',
        orderStatus: 'Backordered',
        requiredDate: '05-11-2024',
        finalisedDate: '20-12-2024',
        totalUnits: '300 units',
    },
    {
        docketID: 'DK-008',
        warehouseName: 'UrbanHub Distribution Center',
        externalReference: 'ER-008',
        orderStatus: 'Completed',
        requiredDate: '17-12-2024',
        finalisedDate: '02-02-2025',
        totalUnits: '400 units',
    },
    {
        docketID: 'DK-009',
        warehouseName: 'Skyline Storage Depot',
        externalReference: 'ER-009',
        orderStatus: 'Returned',
        requiredDate: '22-01-2025',
        finalisedDate: '25-03-2025',
        totalUnits: '50 units',
    },
    {
        docketID: 'DK-010',
        warehouseName: 'Pioneer Warehouse Solutions',
        externalReference: 'ER-010',
        orderStatus: 'Refunded',
        requiredDate: '14-02-2025',
        finalisedDate: '16-04-2025',
        totalUnits: '350 units',
    },
    {
        docketID: 'DK-011',
        warehouseName: 'Evergreen Logistics Hub',
        externalReference: 'ER-011',
        orderStatus: 'Partially Shipped',
        requiredDate: '26-03-2025',
        finalisedDate: '28-05-2025',
        totalUnits: '600 units',
    },
    {
        docketID: 'DK-012',
        warehouseName: 'Horizon Storage Services',
        externalReference: 'ER-012',
        orderStatus: 'Awaiting Payment',
        requiredDate: '08-04-2025',
        finalisedDate: '09-07-2025',
        totalUnits: '125 units',
    },
    {
        docketID: 'DK-013',
        warehouseName: 'Unity Warehouse Complex',
        externalReference: 'ER-013',
        orderStatus: 'Ready for Pickup',
        requiredDate: '19-05-2025',
        finalisedDate: '20-08-2025',
        totalUnits: '175 units',
    },
    {
        docketID: 'DK-014',
        warehouseName: 'SwiftStock Warehousing Solutions',
        externalReference: 'ER-014',
        orderStatus: 'Shipped',
        requiredDate: '13-07-2025',
        finalisedDate: '25-08-2025',
        totalUnits: '100 units',
    },
    {
        docketID: 'DK-015',
        warehouseName: 'PeakLogix Distribution Center',
        externalReference: 'ER-015',
        orderStatus: 'Delivered',
        requiredDate: '05-08-2025',
        finalisedDate: '13-09-2025',
        totalUnits: '225 units',
    },
]

export const containerData = [
    {
        containerNumber: 'C123456',
        sealNumber: 'S987654',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'Yes',
        items: '100',
        pallets: '10',
    },
    {
        containerNumber: 'C654321',
        sealNumber: 'S123456',
        type: '40ft',
        isPalletised: 'No',
        chargeable: 'No',
        items: '200',
        pallets: '0',
    },
    {
        containerNumber: 'C112233',
        sealNumber: 'S223344',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'Yes',
        items: '150',
        pallets: '15',
    },
    {
        containerNumber: 'C445566',
        sealNumber: 'S334455',
        type: '40ft',
        isPalletised: 'No',
        chargeable: 'Yes',
        items: '300',
        pallets: '20',
    },
    {
        containerNumber: 'C778899',
        sealNumber: 'S445566',
        type: '20ft',
        isPalletised: 'Yes',
        chargeable: 'No',
        items: '120',
        pallets: '12',
    },
];


export const linesData = [
    {
        partNumber: 'PN123456',
        description: 'Widget A',
        productCode: 'WIDG-A',
        packUQ: 'Box',
        quantity: '50',
        totalQuantity: '500',
    },
    {
        partNumber: 'PN789012',
        description: 'Widget B',
        productCode: 'WIDG-B',
        packUQ: 'Crate',
        quantity: '30',
        totalQuantity: '300',
    },
    {
        partNumber: 'PN345678',
        description: 'Gadget C',
        productCode: 'GADG-C',
        packUQ: 'Pallet',
        quantity: '20',
        totalQuantity: '200',
    },
    {
        partNumber: 'PN901234',
        description: 'Gadget D',
        productCode: 'GADG-D',
        packUQ: 'Box',
        quantity: '40',
        totalQuantity: '400',
    },
    {
        partNumber: 'PN567890',
        description: 'Device E',
        productCode: 'DEVI-E',
        packUQ: 'Carton',
        quantity: '25',
        totalQuantity: '250',
    },
];

export const reletedInvoiceData = [
    {
        transactionType: 'Purchase',
        terms: 'Net 30',
        invoicesDate: '2023-01-15',
        dueDate: '2023-02-14',
        fullyPaidDate: '2023-02-10',
        canceled: 'No',
        ledger: 'Accounts Payable',
    },
    {
        transactionType: 'Sale',
        terms: 'Net 45',
        invoicesDate: '2023-03-01',
        dueDate: '2023-04-15',
        fullyPaidDate: '2023-04-10',
        canceled: 'No',
        ledger: 'Accounts Receivable',
    },
    {
        transactionType: 'Service',
        terms: 'Net 60',
        invoicesDate: '2023-05-10',
        dueDate: '2023-07-09',
        fullyPaidDate: '2023-07-05',
        canceled: 'No',
        ledger: 'Service Revenue',
    },
    {
        transactionType: 'Return',
        terms: 'Net 15',
        invoicesDate: '2023-06-20',
        dueDate: '2023-07-05',
        fullyPaidDate: '2023-07-02',
        canceled: 'Yes',
        ledger: 'Sales Returns',
    },
    {
        transactionType: 'Adjustment',
        terms: 'Net 30',
        invoicesDate: '2023-07-01',
        dueDate: '2023-07-31',
        fullyPaidDate: '',
        canceled: 'No',
        ledger: 'Inventory Adjustments',
    },
];


export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
