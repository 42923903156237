import { faker } from "@faker-js/faker";

export const itemListInfoGhdgEvolution = [
    {
        label: 'Market Acerage g CO2e/tkm',
        color: 'violet-v2',
    },
    {
        label: 'Actual g CO2e/tkm',
        color: 'blue-v4',
    },

]

export const itemListInfoGhgTrend = [
    {
        label: 'Cargo Volume t',
        color: 'violet-v2',
    },
    {
        label: 'Actual t CO2e',
        color: 'blue-v4',
    },

]

export const dataSimpleLine = [
    {
        name: 'Jan',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2400
    },
    {
        name: 'Feb',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2210
    },
    {
        name: 'Mar',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2290
    },
    {
        name: 'Apr',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2000
    },
    {
        name: 'May',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2181
    },
    {
        name: 'Jun',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2500
    },
    {
        name: 'Jul',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
    {
        name: 'Aug',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
    {
        name: 'Sep',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
    {
        name: 'Oct',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
    {
        name: 'Nov',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
    {
        name: 'Dec',
        pv: faker.number.int({ min: 30, max: 100 }),
        uv: faker.number.int({ min: 30, max: 100 }),
        amt: 2100
    },
];