import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { useReceiptsDetails } from '../receipts-details.service'
import { containerData, metaDummy } from '../../receipts.dummy'
import { IContainers } from '../../receipts.interface'

const Containers = () => {
    const {
        allModalContainers,
        headersContainers,
        ConfigureColumnsModalServiceContainers,
    } = useReceiptsDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IContainers>
                headers={headersContainers}
                data={containerData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() =>
                                ConfigureColumnsModalServiceContainers.openModalHandling()
                            }
                            label="Configure Column"
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalContainers}
        </div>
    )
}

export default Containers
