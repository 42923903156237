import { faker } from '@faker-js/faker'
import { ITotalVolumes, IVolumes } from './volumes.interface'

// Summary Data
export const OceanFCL: IVolumes = {
    title: 'Ocean FCL',
    timing: 'Last 7 Days',
    shipments: {
        total: 792,
        percentage: -31.78,
    },
    volume: {
        total: 72783.6,
        percentage: -15.65,
    },
    weight: {
        total: 50250.1,
        percentage: -35.104,
    },
}

export const LandFCL: IVolumes = {
    title: 'Land FCL',
    timing: 'Last 7 Days',
    shipments: {
        total: 196,
        percentage: 15.29,
    },
    volume: {
        total: 1164.34,
        percentage: 6.36,
    },
    weight: {
        total: 277083.53,
        percentage: 5.63,
    },
}

export const RailFCL: IVolumes = {
    title: 'Rail',
    timing: 'Last 7 Days',
    shipments: {
        total: 46,
        percentage: -13.21,
    },
    volume: {
        total: 72783.6,
        percentage: -2.69,
    },
    weight: {
        total: 50250,
        percentage: 19.59,
    },
}

export const AirFCL: IVolumes = {
    title: 'Air',
    timing: 'Last 7 Days',
    shipments: {
        total: 167,
        percentage: 8.44,
    },
    volume: {
        total: 3032.49,
        percentage: 79.68,
    },
    weight: {
        total: 168391.69,
        percentage: -35.04,
    },
}

export const RoadFCL: IVolumes = {
    title: 'Road',
    timing: 'Last 7 Days',
    shipments: {
        total: 1350,
        percentage: -35.62,
    },
    volume: {
        total: 82.94,
        percentage: -14.65,
    },
    weight: {
        total: 277795.84,
        percentage: -34.47,
    },
}

export const TotalFCL: ITotalVolumes = {
    title: 'Total',
    timing: 'Last 7 Days',
    oceanFCL: {
        total: 167,
        percentage: 8.44,
    },
    oceanLCL: {
        total: 3032.49,
        percentage: 79.68,
    },
    railTEU: {
        total: 168391.69,
        percentage: -35.04,
    },
}

export interface IDataVolumes {
    date: Date
    total: number
}

// List of Data
export const ShipmentsDummy = (): IDataVolumes[] => {
    const data: IDataVolumes[] = []
    for (let i = 0; i < 8; i++) {

        let date = faker.date.between({
            from: '2020-01-01T00:00:00.000Z',
            to: '2030-01-01T00:00:00.000Z',
        })
        let total = faker.number.int({ min: 10, max: 100 })
        data.push({ date: date, total: total })
    }
    return data
}

export const VolumeDummy = (): IDataVolumes[] => {
    const data: IDataVolumes[] = []
    for (let i = 0; i < 8; i++) {
        let date = faker.date.between({
            from: '2020-01-01T00:00:00.000Z',
            to: '2030-01-01T00:00:00.000Z',
        })
        let total = faker.number.int({ min: 10, max: 100 })
        data.push({ date: date, total: total })
    }
    return data
}

export const WeightDummy = (): IDataVolumes[] => {
    const data: IDataVolumes[] = []
    for (let i = 0; i < 8; i++) {
        let date = faker.date.between({
            from: '2020-01-01T00:00:00.000Z',
            to: '2030-01-01T00:00:00.000Z',
        })
        let total = faker.number.int({ min: 10, max: 100 })
        data.push({ date: date, total: total })
    }
    return data
}
