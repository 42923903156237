import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import { useTranslation } from 'react-i18next'

const InventoryFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Input
                    label={t('inventory.warehouse_name').toUpperCase()}
                    placeholder={t('action.enter', {
                        value: t('inventory.warehouse_name'),
                    })}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: '<250',
                            value: '<250',
                        },
                        {
                            label: '>250',
                            value: '>250',
                        },
                    ]}
                    label={t('inventory.total_units').toUpperCase()}
                    onClick={() => {}}
                    placeholder={t('action.select', {
                        value: t('inventory.total_units'),
                    })}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: '<150',
                            value: '<150',
                        },
                        {
                            label: '>150',
                            value: '>150',
                        },
                    ]}
                    label={t('inventory.available_units').toUpperCase()}
                    onClick={() => {}}
                    placeholder={t('action.select', {
                        value: t('inventory.available_units'),
                    })}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: '<100',
                            value: '<100',
                        },
                        {
                            label: '>100',
                            value: '>100',
                        },
                    ]}
                    label={t('inventory.committed_units').toUpperCase()}
                    placeholder={t('action.select', {
                        value: t('inventory.committed_units'),
                    })}
                    onClick={() => {}}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Description',
                            value: 'partDescription',
                        },
                        {
                            label: 'Reserved Units',
                            value: 'reservedUnits',
                        },
                        {
                            label: 'Unit UQ',
                            value: 'unitUQ',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', {
                        value: t('action.filter'),
                    })}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default InventoryFilter
