import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductDataDummy } from './products.dummy'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import { IProduct } from './products.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ProductsConfigureColumns } from './products.static'

const useProducts = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // state
    const [data, setData] = useState(ProductDataDummy)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<ITableColumn<IProduct>[]>([])
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'All Products',
        totalData: ProductDataDummy.length,
        key: 'status',
        value: 'allProducts',
        className: 'brand',
    })

    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabFilter])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setData(
                tabFilter.value === 'allProducts'
                    ? ProductDataDummy
                    : ProductDataDummy.filter(
                          (x) => x.partDescription === tabFilter.value,
                      ),
            )
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // segment general
    const allModal = (
        <>
            <ConfigureColumnsModal<IProduct>
                modalService={ConfigureColumnsModalService}
                columns={ProductsConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
        ConfigureColumnsModalService,
        allModal,
        headers,
    }
}

export default useProducts
