import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import json from './interface/timezones.json'
import { ITimezones } from './interface/timezones.interface'

export const getTimezonesDropdown = (): IDropdownItem<ITimezones>[] => {
    const timezonesJson = json as unknown[] as ITimezones[]
    const timezonesDropdown: IDropdownItem<ITimezones>[] = timezonesJson.map(
        (data: ITimezones) => {
            const dropdown: IDropdownItem<ITimezones> = {
                label: data.Name,
                value: data.olsonDatabaseId,
            }
            return dropdown
        },
    )
    return timezonesDropdown
}
