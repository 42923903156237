import Button from '@components/button/button.component'
import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import { IUseUserAccessForm } from 'client/common/pages/user-acces/user-access-form/user-access-form.service'
import './manage-user-form.style.css'
import useManageUserForm from './manage-user-form.service'
import { useTranslation } from 'react-i18next'

const ManageUserForm = ({ isNew, isProfilePage }: IUseUserAccessForm) => {
    const { t } = useTranslation()
    const { organization, roleDataDropdown, gender, navigate } =
        useManageUserForm()

    const labelNew = isNew
        ? t('menu.manage_user_create')
        : t('menu.manage_user_update')
    const label = isProfilePage ? t('menu.manage_user_details') : labelNew
    const isDisabled = !isProfilePage ? false : true
    const buttonLabel = isNew
        ? t('menu.manage_user_create')
        : t('menu.manage_user_update')
    const cancelButtonLabel = t('action.back')

    return (
        <>
            <div
                className={`container-global create-user transition-opacity duration-500 `}
            >
                <div className="border-b border-logistical-gray-ver7 ">
                    <div className="p-4 text-size-S font-bold">
                        {label.toUpperCase()}
                    </div>
                </div>

                {/* Form */}
                <div className="content">
                    <div className="content-flex content-flex-1">
                        <div className="text-size-S font-bold mb-3">
                            {t('users.user_profile')}
                        </div>
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.full_name')}
                            placeholder={t('action.enter', {
                                value: t('users.full_name'),
                            })}
                            name={'fullName'}
                            required
                        />
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.job_title')}
                            placeholder={t('action.enter', {
                                value: t('users.job_title'),
                            })}
                            name={'jobTitle'}
                            required
                        />
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.job_category')}
                            placeholder={t('action.enter', {
                                value: t('users.job_category'),
                            })}
                            name={'jobCategory'}
                            required
                        />
                        <Dropdown
                            useUppercaseLabel={true}
                            label={t('users.gender')}
                            placeholder={t('action.select', {
                                value: t('users.gender'),
                            })}
                            options={gender}
                        />

                        <div className="text-size-S font-bold mb-3 mt-3">
                            {t('users.user_address')}
                        </div>
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.country')}
                            placeholder={t('action.enter', {
                                value: t('users.country'),
                            })}
                            name={'country'}
                            required
                        />
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.city')}
                            placeholder={t('action.enter', {
                                value: t('users.city'),
                            })}
                            name={'city'}
                            required
                        />
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.address_detail')}
                            placeholder={t('action.enter', {
                                value: t('users.address_detail'),
                            })}
                            name={'address'}
                            required
                        />

                        <div className="flex gap-2 flex-wrap mt-4">
                            <div className="flex-1 flex flex-col space-y-3">
                                <div className="text-size-S font-bold ">
                                    {t('users.user_contact')}
                                </div>

                                <Input
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.mobile_phone_number')}
                                    placeholder={t('action.enter', {
                                        value: t('users.mobile_phone_number'),
                                    })}
                                    name={'mobilePhone'}
                                />
                                <Input
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.work_phone_number')}
                                    placeholder={t('action.enter', {
                                        value: t('users.work_phone_number'),
                                    })}
                                    name={'workPhone'}
                                />
                                <Input
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.home_phone')}
                                    placeholder={t('action.enter', {
                                        value: t('users.home_phone'),
                                    })}
                                    name={'homePhone'}
                                />
                            </div>

                            <div className="flex-1 flex flex-col space-y-3">
                                <div className="text-size-S font-bold">
                                    {t('users.emergency_contact')}
                                </div>

                                <Input
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.emergency_contact')}
                                    placeholder={t('action.enter', {
                                        value: t('users.emergency_contact'),
                                    })}
                                    name={'emergencyContactName'}
                                />
                                <Input
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.emergency_home_phone')}
                                    placeholder={t('action.enter', {
                                        value: t('users.emergency_home_phone'),
                                    })}
                                    name={'emergencyHomePhone'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="content-flex content-flex-2">
                        <div className="text-size-S font-bold mb-3">
                            {t('users.user_account')}
                        </div>

                        {isProfilePage ? (
                            <Input
                                useUppercaseLabel={true}
                                disabled={true}
                                label={t('users.organisation')}
                                placeholder={t('action.enter', {
                                    value: t('users.organisation'),
                                })}
                                name={'organizationLabel'}
                                required
                            />
                        ) : (
                            <Dropdown
                                useUppercaseLabel={true}
                                disabled={isDisabled}
                                label={t('users.organisation')}
                                placeholder={t('action.enter', {
                                    value: t('users.organisation'),
                                })}
                                required
                                options={organization}
                            />
                        )}

                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.email')}
                            placeholder={t('action.enter', {
                                value: t('users.email'),
                            })}
                            name={'portalEmail'}
                            required
                        />
                        <Input
                            useUppercaseLabel={true}
                            disabled={isDisabled}
                            label={t('users.username')}
                            placeholder={t('action.enter', {
                                value: t('users.username'),
                            })}
                            name={'portalLogin'}
                            required
                            autoComplete="new-username"
                            onFocus={(event) => {
                                event.target.setAttribute('autocomplete', 'off')
                                console.log(event.target.autocomplete)
                            }}
                        />

                        {!isNew ? (
                            <></>
                        ) : (
                            <Input
                                useUppercaseLabel={true}
                                disabled={isDisabled}
                                label={t('login.password')}
                                placeholder={t('action.enter', {
                                    value: t('login.password'),
                                })}
                                name="password"
                                type="password"
                                required
                                autoComplete="asdsd"
                            />
                        )}
                        {isProfilePage ? (
                            <Input
                                useUppercaseLabel={true}
                                disabled={isDisabled}
                                label={t('users.role')}
                                placeholder={t('action.select', {
                                    value: t('users.role'),
                                })}
                                name={'roleDropdown'}
                                required
                            />
                        ) : (
                            <Dropdown
                                useUppercaseLabel={true}
                                label={t('users.role')}
                                placeholder={t('action.select', {
                                    value: t('users.role'),
                                })}
                                required
                                options={roleDataDropdown}
                            />
                        )}

                        {isProfilePage ? (
                            <>
                                <div className="text-size-S font-bold mb-3">
                                    Update Password
                                </div>
                                <Input
                                    useUppercaseLabel={true}
                                    label={t('login.current_password')}
                                    placeholder={t('action.enter', {
                                        value: t('login.current_password'),
                                    })}
                                    name={'currentPassword'}
                                    type="password"
                                    required
                                />
                                <Input
                                    useUppercaseLabel={true}
                                    label={t('login.new_password')}
                                    placeholder={t('action.enter', {
                                        value: t('login.new_password'),
                                    })}
                                    name={'newPassword'}
                                    required
                                    autoComplete="disabled"
                                    type="password"
                                />
                                <Input
                                    useUppercaseLabel={true}
                                    label={t('login.retype_new_password')}
                                    placeholder={t('action.enter', {
                                        value: t('login.retype_new_password'),
                                    })}
                                    name={'newPasswordConfirmation'}
                                    required
                                    autoComplete="disabled"
                                    type="password"
                                />
                                <div className="flex justify-end">
                                    <Button
                                        type="button"
                                        onClick={() => {}}
                                        label={t('login.update_password')}
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>

                {/* Button */}
                <div className="flex justify-between p-4">
                    {!isProfilePage ? (
                        <Button
                            onClick={() => {
                                navigate(-1)
                            }}
                            useUpperCase={true}
                            label={cancelButtonLabel}
                        />
                    ) : null}
                    {!isProfilePage ? (
                        <Button
                            type="button"
                            useUpperCase={true}
                            variant="brand"
                            onClick={() => {
                                navigate('/manage-users')
                            }}
                            label={buttonLabel}
                        />
                    ) : null}
                </div>

                {/* <SelectModuleModal
                    modalService={modalService}
                    moduleList={moduleAdjustmentList}
                    setModalSelectedPhase1={(selected) => {
                        setModalSelectedPhase1(selected)
                    }}
                    setModalSelectedPhase2={(selected) => {
                        setModalSelectedPhase2(selected)
                    }}
                    setAccessHandling={(indexAction: number) => {
                        setAccess(indexAction)
                    }}
                    modalSelectedPhase1={modalSelectedPhase1}
                    modalSelectedPhase2={modalSelectedPhase2}
                    setAllAccess={() => {
                        setAllAccess({})
                    }}
                /> */}
            </div>
        </>
    )
}

export default ManageUserForm
