import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { ManageUserHeader, ManageUserStatusHeader } from './manage-users.static'
import ManageUsersFilterOverlay from './components/manage-users-filter-overlay.component'
import ManageUsersFilter from './components/manage-users-filter.component'
import useManageUsers from './manage-users.service'
import { ManageUsersDataDummy, metaDummy } from './manage-users.dummy'
import { IManageUser } from './manage-users.interface'
import { useTranslation } from 'react-i18next'

const ManageUsers = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        navigate,
        setTabFilter,
        allModal,
        headers,
        ConfigureColumnsModalService,
    } = useManageUsers()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={ManageUserStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('users.user_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ManageUsersFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('create/')
                        },
                        label: t('menu.manage_user_new').toUpperCase(),
                        className: '!text-size-S',
                    }}
                />

                <Table<IManageUser>
                    headers={headers}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={function (data): void {
                        const id = data.userID
                        navigate('details/' + id)
                    }}
                    enableExport={false}
                    modalService={generateReportModalService}
                    components={<ManageUsersFilter isGenerateReport={true} />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={ManageUsersDataDummy}
                    GenerateReportHeaders={ManageUserHeader}
                    removeFilter={() => {}}
                />
            </div>
            {allModal}
        </div>
    )
}

export default ManageUsers
