import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'

const OriginForm = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>{t('bookings.origin')}</div>
            <div className="flex flex-col gap-3 pt-3">
                <FormDropdown
                    label={t('bookings.origin_port')}
                    name={`originPort`}
                    placeholder={t('action.enter', {
                        value: t('bookings.origin_port'),
                    })}
                    options={[
                        {
                            label: 'Melbourne',
                            value: 'Melbourne',
                        },
                    ]}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.pickup_requested_by')}
                    name={`pickupRequestedBy`}
                    placeholder={t('action.enter', {
                        value: t('bookings.pickup_requested_by'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.pickup_equipment_needed')}
                    name={`pickupEquipmentNeeded`}
                    placeholder={t('action.enter', {
                        value: t('bookings.pickup_equipment_needed'),
                    })}
                    parentDivClassName="!mb-0"
                />
            </div>
        </div>
    )
}

export default OriginForm
