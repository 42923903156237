import Summary from './summary.component'
import { IVolumesSummary } from '../volumes.interface'
import LineCharts from '@components/rechart-component/line/line-charts.component'

interface IChartCardProps {
    className: string
    title: string
    data: any
    summaryTotal: IVolumesSummary | undefined
    unit: string
}

function ChartCard({
    className,
    title,
    data,
    summaryTotal,
    unit,
}: IChartCardProps) {
    const { percentage, total } = summaryTotal as IVolumesSummary
    const isIncrease = percentage >= 0 ? true : false

    return (
        <div className={`${className} grid grid-rows-12 content-start`}>
            <Summary
                className="grid row-span-4"
                title={title}
                isIncrease={isIncrease}
                total={total}
                percentage={percentage}
                unit={unit}
            />
            <LineCharts className="grid row-span-8" data={data} />
        </div>
    )
}

export default ChartCard
