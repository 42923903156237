import { useState } from 'react'
import { ManageUsersDataDummy } from '../manage-users.dummy'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal } from '@components//modal/modal.service'

const useManageUserDetails = () => {
    // initial
    const { id } = useParams()
    const navigate = useNavigate()

    // state
    const [data, setData] = useState(ManageUsersDataDummy)

    // modal service
    const modalConfirmationService = useModal()

    function openEmailClient() {
        var emailAddress = 'test@gmail.com' // Replace with the desired email address
        var subject = 'Subject'
        var body = '--- Body ---'

        var mailtoUrl =
            'mailto:' +
            encodeURIComponent(emailAddress) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(body)

        window.open(mailtoUrl)
    }

    return {
        id,
        data,
        modalConfirmationService,
        navigate,
        setData,
        openEmailClient,
    }
}

export default useManageUserDetails
