import { useState } from 'react'
import Detail from '../detail.component'
import Header from '../components/header.component'
import FilterSection from '../components/filter-section.component'
import { paymentInvoiceCompletion, paymentInvoiceDetail } from './row3.dummy'
import BarChartCustom from '@components/rechart-component/bar/bar-chart.component'
import { useTranslation } from 'react-i18next'

function BarChartAnalytics() {
    const [isFilter, setIsFilter] = useState<boolean>(false)
    const { t } = useTranslation()
    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="h-5 row-span-1"
                title={t('dashboard.payment_invoice_completion')}
                filter={` ${t('dashboard.last_days', {
                    value: 7,
                })}(17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />

            {isFilter ? (
                <div className="lg:row-span-6 md:row-span-6 px-2">
                    <FilterSection
                        className="grid gap-3 mt-6"
                        displayedInform={true}
                        filterBy={true}
                        dateRange={true}
                    />
                </div>
            ) : (
                <>
                    <Detail
                        className="grid-cols-4 row-span-3 content-center"
                        data={paymentInvoiceDetail}
                    />
                    <BarChartCustom
                        data={paymentInvoiceCompletion}
                        className="row-span-8"
                        tooltipSingle="Total Invoices"
                    />
                </>
            )}
        </div>
    )
}

export default BarChartAnalytics
