var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SpinnerTable from "@components/spinner-table/spinner-table.component";
import Pagination from "@components/pagination/pagination.component";
import { useTable } from "./table-card.service";
import Button from "@components/button/button.component";
import EmptyResult from "@components/empty-result/empty-result.component";
import PoGenerateReportModal from "./component/po-generate-report-modal.component";
import { TableCardRow } from "./component/table-card-row.component";
function TableCard(_a) {
    var _b = _a.usePagination, usePagination = _b === void 0 ? true : _b, props = __rest(_a, ["usePagination"]);
    var loadingMessage = "loading ".concat(props.moduleTitle, " Data . . .");
    var notFoundMessage = "No data found";
    var generatedData = useTable({
        data: props.data,
        tabFilterItem: props.tabFilterItem,
    }).generatedData;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "h-full flex flex-col overflow-auto" }, { children: [_jsx("div", __assign({ className: "flex-1 overflow-hidden ".concat(props.containerClassname) }, { children: props.loading === true ? (_jsx(SpinnerTable, { message: loadingMessage })) : props.data.length <= 0 ? (_jsx(EmptyResult, { message: notFoundMessage })) : (_jsx("div", __assign({ className: "relative my-2 overflow-auto w-full h-[calc(100vh-260px)] rounded themes-bg-white" }, { children: generatedData.map(function (data, idx) {
                                return (_jsx("div", { children: _jsx(TableCardRow, { data: data, moduleTitle: props.moduleTitle, summaryDetail: props.summaryDetail }) }, "id-row-".concat(idx)));
                            }) }))) })), (props === null || props === void 0 ? void 0 : props.modalService) && (_jsx(PoGenerateReportModal, { modalService: props === null || props === void 0 ? void 0 : props.modalService, resetFilter: props === null || props === void 0 ? void 0 : props.resetFilter, components: props === null || props === void 0 ? void 0 : props.components, moduleTitle: props === null || props === void 0 ? void 0 : props.moduleTitle, data: props === null || props === void 0 ? void 0 : props.data, headers: props === null || props === void 0 ? void 0 : props.headers, exportType: "xlxs", getDataGenerate: props === null || props === void 0 ? void 0 : props.getDataGenerate, generateReportData: props === null || props === void 0 ? void 0 : props.generateReportData, GenerateReportHeaders: props === null || props === void 0 ? void 0 : props.GenerateReportHeaders, removeFilter: props === null || props === void 0 ? void 0 : props.removeFilter }))] })), usePagination && (_jsxs("div", __assign({ className: "border-t themes-border-color flex justify-between" }, { children: [_jsx(Pagination, { meta: props.meta, previousHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.previousHandling) && props.previousHandling(page);
                        }, nextHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.nextHandling) && props.nextHandling(page);
                        } }), _jsxs("div", __assign({ className: "m-4 flex gap-2" }, { children: [props.additionalButtonBottom, props.enableExport ? (_jsx(Button, { label: "Generate Report", onClick: function () {
                                    var _a;
                                    (_a = props.modalService) === null || _a === void 0 ? void 0 : _a.openModalHandling();
                                } })) : ("")] }))] })))] }));
}
export default TableCard;
