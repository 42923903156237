import SimpleTable from '@components/simple-table/simple-table.component'
import Button from '@components/button/button.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { useReceiptsDetails } from '../receipts-details.service'
import { metaDummy } from '../../receipts.dummy'
import { IMilestones } from '../../receipts.interface'
import { milestoneData } from '../receipts-details.static'
import { useTranslation } from 'react-i18next'

const Milestones = () => {
    const { t } = useTranslation()
    const {
        allModalMilestones,
        headersMilestones,
        ConfigureColumnsModalServiceMilestones,
    } = useReceiptsDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)] grid grid-cols-12">
            <div className="col-span-3 border-y-2 justify-between">
                <div className="flex justify-between items-center px-3 py-2 text-[16px] border-b-2">
                    <span className="themes-text-gray-v5 ">
                        {t('milestone.selected_milestone')}
                    </span>
                </div>
                <div className="">
                    <div className="p-3">
                        <Dropdown
                            options={[
                                {
                                    label: 'Detailed Goods Description',
                                    value: 'Detailed Goods Description',
                                },
                            ]}
                            label={t('milestone.tracking_number')}
                            onClick={() => {}}
                            placeholder={t('milestone.tracking_number')}
                            isClearable={true}
                            isMultiSelect={true}
                            isSearchable={true}
                        />
                    </div>

                    <div className="flex justify-between gap-5 items-center p-3 text-[16px] border-y-2">
                        <span className="themes-text-gray-v5 min-w-max">
                            {t('milestone.filtered_by')}
                        </span>
                        <Dropdown
                            options={[
                                {
                                    label: 'Detailed Goods Description',
                                    value: 'Detailed Goods Description',
                                },
                            ]}
                            onClick={() => {}}
                            label={t('action.add', {
                                value: t('action.filter'),
                            })}
                            placeholder={t('action.select', {
                                value: t('action.filter'),
                            })}
                            isClearable={true}
                            isMultiSelect={true}
                            isSearchable={true}
                        />
                    </div>

                    <div className="p-3">
                        <div className="flex items-center">
                            <Dropdown
                                options={[
                                    {
                                        label: 'Detailed Goods Description',
                                        value: 'Detailed Goods Description',
                                    },
                                ]}
                                onClick={() => {}}
                                label={t('action.add', {
                                    value: t('action.filter'),
                                })}
                                placeholder={t('action.select', {
                                    value: t('action.filter'),
                                })}
                                isClearable={true}
                                isMultiSelect={true}
                                isSearchable={true}
                            />
                            <i className="ri-delete-bin-line themes-text-hover-red-v3 text-[24px] pl-5 pr-2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-9">
                <SimpleTable<IMilestones>
                    headers={headersMilestones}
                    data={milestoneData}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    additionalButtonBottom={
                        <>
                            <Button
                                onClick={() =>
                                    ConfigureColumnsModalServiceMilestones.openModalHandling()
                                }
                                label="Configure Column"
                                className="w-[10rem] ml-0"
                                variant="brand-inverse"
                            />
                        </>
                    }
                />
                {allModalMilestones}
            </div>
        </div>
    )
}

export default Milestones
