import Dropdown from '@components/dropdown/dropdown.component'
import { useTranslation } from 'react-i18next'

const ProductFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: '<10 KG',
                            value: '<10 KG',
                        },
                        {
                            label: '>10 KG',
                            value: '>10 KG',
                        },
                    ]}
                    label={t('products.weight')}
                    placeholder={t('action.select', {
                        value: t('products.weight'),
                    })}
                    onClick={() => {}}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Model',
                            value: 'model',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', { value: t('action.filter') })}
                    isClearable={true}
                    isMultiSelect={false}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default ProductFilter
