import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    fetchNewsData,
    fetchScfiData,
} from 'client/ifb/repository/dashboard.repository'
import { setNews, setScfiIndex } from './dashboard.slice'
import { DashboardCardDefault } from './row1/row1.dummy'
import { news } from './dashboard.dummy'

const useDashboard = () => {
    const dispatch = useDispatch()
    const [dataCard, setDataCard] = useState(DashboardCardDefault)
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = async () => {
        const dataNews = news
        const ScfiNews = await fetchScfiData()
        dispatch(setNews(dataNews))
        dispatch(setScfiIndex(ScfiNews))

        // Update dataCard based on DashboardCardDefault
        const updatedDataCard = DashboardCardDefault.map((item) => {
            if (item.title === 'dashboard.scfi_index' && ScfiNews) {
                return {
                    ...item,
                    total: `US$ ${'0'}`,
                    summaryTotal: `US$ ${'0'}`,
                }
            }
            return item
        })
        setDataCard(updatedDataCard)
        setIsLoading(false)
    }

    useEffect(() => {
        setIsLoading(true)
        fetchData()
    }, [])

    return {
        dataCard,
        fetchNewsData,
        fetchScfiData,
        isLoading,
    }
}

export default useDashboard
