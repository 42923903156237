var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell, } from 'recharts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
function BarChartCustom(_a) {
    var data = _a.data, tooltipSingle = _a.tooltipSingle, className = _a.className;
    var barColors = [
        '#D9DDE1',
        '#D9DDE1',
        '#D9DDE1',
        '#D9DDE1',
        '#D9DDE1',
        '#D9DDE1',
        '#D8E7FF',
    ];
    var _b = useState(window.innerWidth), widthSize = _b[0], setWidthSize = _b[1];
    var _c = useState(window.innerHeight), heightSize = _c[0], setHeightSize = _c[1];
    var t = useTranslation().t;
    var _d = useState({
        width: widthSize,
        height: 235,
    }), dimension = _d[0], setDimension = _d[1];
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        var newDimension = __assign({}, dimension);
        if (heightSize === 1080)
            newDimension.height = 250;
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440;
        }
        else if (heightSize === 911)
            newDimension.height = 320;
        setDimension(newDimension);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    var formatXAxis = function (tickFormat) {
        return tickFormat !== 0 ? tickFormat / 1000 + '' : '0';
    };
    var CustomTooltip = function (_a) {
        var _b, _c, _d, _e;
        var active = _a.active, payload = _a.payload;
        if (active && payload && payload.length) {
            return (_jsxs("div", __assign({ className: "flex flex-row gap-2 bg-gray-700 rounded-lg text-white text-xs font-normal px-4 py-2 font-['Basis Grotesque Arabic Pro']" }, { children: [_jsx("p", { className: "w-2 h-2 bg-blue-100 rounded-full self-center" }), _jsx("p", __assign({ className: "label" }, { children: "".concat(((_c = (_b = payload[0]) === null || _b === void 0 ? void 0 : _b.payload) === null || _c === void 0 ? void 0 : _c.toolTipName) ? (_e = (_d = payload[0]) === null || _d === void 0 ? void 0 : _d.payload) === null || _e === void 0 ? void 0 : _e.toolTipName : '', " ").concat(tooltipSingle ? tooltipSingle : '', " : ").concat(payload[0].value) }))] })));
        }
        return null;
    };
    var RoundedBar = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height;
        return (_jsx("g", { children: _jsx("rect", { x: x, y: y, width: width, height: height, fill: props.fill, rx: 5, ry: 5 }) }));
    };
    var CustomizedXAxisTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload, width = _a.width;
        if (payload === undefined) {
            if (width === undefined)
                width = 0;
            payload = { value: '' };
        }
        var words = payload.value.split(' ');
        var maxWordsPerLine = 3;
        var lines = [];
        for (var i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
        }
        var isTextOverlapping = width ? width < lines.join('').length * 15 : 0;
        return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: isTextOverlapping
                ? null
                : lines.map(function (line, index) {
                    var nameLine = line;
                    return (_jsx("text", __assign({ x: width ? width / 1000 : 0, y: index * 16, dy: 16, textAnchor: "middle", fill: "#666", fontSize: heightSize < 900 ? 9 : 12 }, { children: t(nameLine) }), index));
                }) })));
    };
    return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%", className: className }, { children: _jsxs(BarChart, __assign({ width: 150, height: 40, data: data, margin: {
                top: 5,
                right: 5,
                left: -25,
                bottom: -10,
            } }, { children: [_jsx(CartesianGrid, { stroke: "#f5f5f5" }), _jsx(XAxis, { dataKey: "name", interval: 0, height: 70, tick: _jsx(CustomizedXAxisTick, {}) }), _jsx(YAxis, { tickFormatter: function (tick) { return formatXAxis(tick); }, type: 'number' }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(Bar, __assign({ dataKey: "number", fill: "#D9DDE1", shape: _jsx(RoundedBar, {}) }, { children: data.map(function (_entry, index) { return (_jsx(Cell, { fill: barColors[index % 20] }, "cell-".concat(index))); }) }))] })) })));
}
export default BarChartCustom;
