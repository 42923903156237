import React from 'react'
import Dropdown from '@components/dropdown/dropdown.component'
import DatePicker from '@components/date-picker/date-picker.component'
import { optionDates, optionDisplay } from '../dashboard.dummy'

interface FilterSectionProps {
    className?: string
    displayedInform?: boolean
    filterBy?: boolean
    dateRange?: boolean
    configure?: boolean
    selectedTable?: boolean
}

const FilterSection: React.FC<FilterSectionProps> = ({
    className = '',
    displayedInform = false,
    filterBy = false,
    dateRange = false,
    configure = false,
    selectedTable = false,
}) => {
    return (
        <div className={`${className}`}>
            {displayedInform && (
                <Dropdown
                    options={optionDates}
                    useBorder={true}
                    label="DISPLAYED INFORMATION"
                />
            )}
            {filterBy && (
                <Dropdown
                    options={optionDisplay}
                    useBorder={true}
                    label="FILTERED BY"
                />
            )}
            {dateRange && <DatePicker isRange={true} label="START DATE" />}
            {configure && (
                <Dropdown
                    placeholder="Select to add table column"
                    label="CONFIGURE TABLE COLUMN"
                    isClearable={true}
                    isSearchable={true}
                />
            )}
            {selectedTable && (
                <Dropdown
                    label="SELECTED TABLE COLUMN"
                    isMultiSelect={true}
                    isClearable={true}
                    isSearchable={true}
                />
            )}
        </div>
    )
}

export default FilterSection
