var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dropdown from "@components/dropdown/dropdown.component";
import DatePicker from "@components/date-picker/date-picker.component";
var FilterSection = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.displayedInform, displayedInform = _c === void 0 ? false : _c, _d = _a.filterBy, filterBy = _d === void 0 ? false : _d, _e = _a.dateRange, dateRange = _e === void 0 ? false : _e, _f = _a.configure, configure = _f === void 0 ? false : _f, _g = _a.selectedTable, selectedTable = _g === void 0 ? false : _g, _h = _a.optionDates, optionDates = _h === void 0 ? [] : _h, _j = _a.optionDisplay, optionDisplay = _j === void 0 ? [] : _j;
    return (_jsxs("div", __assign({ className: "".concat(className) }, { children: [displayedInform && (_jsx(Dropdown, { options: optionDates, useBorder: true, label: "DISPLAYED INFORMATION" })), filterBy && (_jsx(Dropdown, { options: optionDisplay, useBorder: true, label: "FILTERED BY" })), dateRange && _jsx(DatePicker, { isRange: true, label: "START DATE" }), configure && (_jsx(Dropdown, { placeholder: "Select to add table column", label: "CONFIGURE TABLE COLUMN", isClearable: true, isSearchable: true })), selectedTable && (_jsx(Dropdown, { label: "SELECTED TABLE COLUMN", isMultiSelect: true, isClearable: true, isSearchable: true }))] })));
};
export default FilterSection;
