import { useState } from 'react'
import Logo from '@assets/img/logos/LoglinesLogo.png'
import VideoBGIFB from '@assets/video/LogLines_Login6mb.mp4'

import '../login.style.css'
import IFBLoginForm from './ifb-login-form.component'
import IFBForgotPasswordForm from './ifb-forgot-password-form.component'
import IFBTrackShipment from './ifb-track-shipments'
import { useForgotPassword } from '../login.service'
const IFBLoginPage = () => {
    const { isLoginForm, setIsLoginForm } = useForgotPassword()
    const [isTrackShipment, setIsTrackShipment] = useState<boolean>(false)

    const component = isLoginForm ? (
        <IFBLoginForm
            forgotPasswordToggle={() => {
                setIsLoginForm(false)
            }}
            setIsLoginForm={() => {
                setIsTrackShipment(true)
            }}
        />
    ) : (
        <IFBForgotPasswordForm
            backFunction={() => {
                setIsLoginForm(true)
            }}
        />
    )

    const content = () => {
        if (isTrackShipment) {
            return (
                <IFBTrackShipment
                    setIsLoginForm={() => {
                        setIsTrackShipment(false)
                    }}
                />
            )
        } else {
            return (
                <div className="IFB-login-bg font-bahnschrift overflow-hidden">
                    <video autoPlay loop muted className="background-video">
                        <source src={VideoBGIFB} type="video/mp4" />
                    </video>
                    <div className="min-h-screen flex items-center justify-center">
                        <div className="mx-auto my-auto mobile:w-full sm:w-[90vw] md:w-[60vw] lg:w-[40vw] xl:w-[30vw]">
                            <div className="login-container px-10 sm:px-7 py-10 sm:py-7 rounded-md shadow-md">
                                {/* logo */}
                                <img
                                    src={Logo}
                                    className="login-logo mx-auto"
                                    alt="Logo"
                                />

                                {/* Login Form */}
                                {component}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return <>{content()}</>
}
export default IFBLoginPage
