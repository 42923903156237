var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AreaChart, ResponsiveContainer, Area } from 'recharts';
function LineCharts(_a) {
    var data = _a.data, className = _a.className;
    return (_jsx("div", __assign({ className: className }, { children: _jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(AreaChart, __assign({ width: 730, height: 250, data: data, margin: {
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 5
                } }, { children: [_jsx("defs", { children: _jsx("linearGradient", __assign({ id: "colorUv", x1: "0", y1: "0", x2: "0", y2: "1" }, { children: _jsx("stop", { offset: "5%", stopColor: "#D9D9D9", stopOpacity: 0.5 }) })) }), _jsx(Area, { type: "monotone", dataKey: "total", stroke: "#8D99A5", fillOpacity: 1, fill: "url(#colorUv)" })] })) })) })));
}
export default LineCharts;
