import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { useTranslation } from 'react-i18next'

const ChargesForm = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>{t('bookings.charges')}</div>
            <div className="grid grid-cols-2 gap-3 pt-3">
                <FormDropdown
                    label={t('bookings.incoterm')}
                    name={`details.incoTerm`}
                    placeholder={t('action.enter', {
                        value: t('bookings.incoterm'),
                    })}
                    options={[
                        {
                            label: 'Free On Board',
                            value: 'Free On Board',
                        },
                    ]}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.additional_terms')}
                    name={`details.additionalTerms`}
                    placeholder={t('action.enter', {
                        value: t('bookings.additional_terms'),
                    })}
                    parentDivClassName="!mb-0"
                />
                <FormInput
                    label={t('bookings.goods_value')}
                    name={`details.goodsValue`}
                    placeholder={t('action.enter', {
                        value: t('bookings.goods_value'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                />
                <FormInput
                    label={t('bookings.goods_value_currency')}
                    name={`details.goodsValueCurrency`}
                    placeholder={t('action.enter', {
                        value: t('bookings.goods_value_currency'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                />
                <FormInput
                    label={t('bookings.insurance_value')}
                    name={`details.insuranceValue`}
                    placeholder={t('action.enter', {
                        value: t('bookings.insurance_value'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                />
                <FormInput
                    label={t('bookings.insurance_value_currency')}
                    name={`details.insuranceValueCurrency`}
                    placeholder={t('action.enter', {
                        value: t('bookings.insurance_value_currency'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                />
                <FormDropdown
                    label={t('bookings.charges_apply')}
                    name={`details.chargesApply`}
                    placeholder={t('action.select', {
                        value: t('bookings.insurance_value_currency'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                    options={[
                        {
                            label: 'Show Collect Charges',
                            value: 'Show Collect Charges',
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default ChargesForm
