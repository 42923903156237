var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
export default function Detail(_a) {
    var className = _a.className, data = _a.data;
    var t = useTranslation().t;
    return (_jsx("div", __assign({ className: "grid ".concat(className) }, { children: data === null || data === void 0 ? void 0 : data.map(function (item, index) {
            return (_jsxs("div", __assign({ className: "" }, { children: [_jsx("div", __assign({ className: "grid grid-cols-2 grid-rows-1 whitespace-nowrap text-gray-400 text-xs font-bold font-['Basis Grotesque Arabic Pro']" }, { children: _jsx("div", __assign({ className: "text-gray-400 lg:text-[12px] md:text-xs font-normal font-['Basis Grotesque Arabic Pro']" }, { children: t(item.mode) })) })), _jsx("div", __assign({ className: "grid grid-cols-2 grid-row-1" }, { children: _jsx("div", __assign({ className: "col-span-3 text-blue-950 lg:text-[14px] md:text-xl font-bold font-['Basis Grotesque Arabic Pro'] gap-2 whitespace-nowrap" }, { children: item.detail })) }))] }), index));
        }) })));
}
