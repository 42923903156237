
import { useTranslation } from "react-i18next";
import ChartCard from "./components/chart-card.component"
import Header from "./components/header.component"
import { IDataVolumes } from "./volumes.dummy";
import { ITotalVolumes, IVolumes } from "./volumes.interface";

interface IVolumesChartsProps {
    isMode: boolean;
    data1: IDataVolumes[]
    data2: IDataVolumes[]
    data3: IDataVolumes[]
    summary: ITotalVolumes & IVolumes
}

function VolumesCharts({ isMode, data1, data2, data3, summary }: IVolumesChartsProps) {
    const { title, shipments, volume, weight, oceanFCL, oceanLCL, railTEU } = summary
    const { t } = useTranslation()
    const getTitle = (): string[] => {
        if (isMode) {
            return [t('volumes.total_shipments'), t('volumes.total_volume'), t('volumes.total_weight')]
        } else {
            return ['Ocean FCL (TEU)', 'Ocean LCL (CBM)', 'Rail (TEU)']
        }
    }

    return (
        <div className="grid grid-rows-12 h-full content-start">
            <Header
                className="content-center h-5 row-span-1"
                title={title}
                filter={t('dashboard.last_days', {
                    value: 7,
                })}
            />
            <div className="row-span-11 grid grid-rows-12 ">
                <ChartCard className="content-center row-span-4" title={getTitle()[0]} data={data1} summaryTotal={isMode ? shipments : oceanFCL} unit="" />
                <ChartCard className="content-center row-span-4" title={getTitle()[1]} data={data2} summaryTotal={isMode ? volume : oceanLCL} unit="m³" />
                <ChartCard className="content-center row-span-4" title={getTitle()[2]} data={data3} summaryTotal={isMode ? weight : railTEU} unit="kg" />
            </div>

        </div>
    )
}

export default VolumesCharts