import Tab from '@components/tab/tab.component'
import { formBookingStatusHeader } from '../create-booking.static'
import { ITabItem } from '@components/tab/tab.interface'
import { FormikContextType, FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import { NavigateFunction } from 'react-router-dom'
import { ICreateBokingValidation } from 'client/ifb/form-validation/booking-details.validation'

const FormCreateBooking = ({
    tabFilter,
    setTabFilter,
    getContentForm,
    formik,
    navigate,
}: {
    tabFilter: ITabItem
    setTabFilter: React.Dispatch<React.SetStateAction<ITabItem>>
    getContentForm: () => JSX.Element
    formik: FormikContextType<ICreateBokingValidation>
    navigate: NavigateFunction
}) => {
    return (
        <div>
            <Tab
                containerClassName="px-3 pt-3"
                items={formBookingStatusHeader}
                tabFilter={tabFilter}
                onChange={(item) => {
                    setTabFilter(item)
                }}
            />
            <div className="border-b"></div>

            <FormikProvider value={formik}>
                <div className="h-[calc(100vh-13.8rem)] overflow-auto">
                    {getContentForm()}
                </div>
            </FormikProvider>
            <div className="border-b"></div>
            <div className="p-3 flex justify-between">
                <Button
                    onClick={() => navigate(-1)}
                    label="Back"
                    className="w-btnRegularWidth"
                />
                <Button
                    onClick={() => formik.submitForm()}
                    label="Save"
                    className="w-btnRegularWidth"
                    variant="brand"
                />
            </div>
        </div>
    )
}

export default FormCreateBooking
