var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ResponsiveContainer } from 'recharts';
var ProgressBar = function (_a) {
    var data = _a.data;
    var total = data.reduce(function (sum, item) { return sum + item.value; }, 0);
    var intervals = [];
    // Generate intervals
    for (var i = 0; i <= total; i += 10) {
        intervals.push(i);
    }
    return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs("div", __assign({ className: "flex-grow flex flex-col item-start h-full" }, { children: [_jsx("div", __assign({ className: "flex-grow my-[8%]" }, { children: _jsxs("div", __assign({ className: "relative w-full bg-gray-300 h-6 flex rounded-none" }, { children: [data.map(function (item, index) {
                                var percentage = (item.value / total) * 100;
                                var showTextTopRight = item.textTopRight &&
                                    (index === 0 ||
                                        item.color !== data[index - 1].color);
                                var showTextBotRight = item.textBotRight &&
                                    (index === data.length - 1 ||
                                        item.color !== data[index + 1].color);
                                var showTextTopLeft = item.textTopLeft &&
                                    (index === 0 ||
                                        item.color !== data[index - 1].color);
                                var showTextBotLeft = item.textBotLeft &&
                                    (index === data.length - 1 ||
                                        item.color !== data[index + 1].color);
                                return (_jsxs("div", __assign({ className: "relative  h-[150%]   themes-bg-".concat(item.color), style: { width: "".concat(percentage, "%") }, title: "Item ".concat(index + 1, ": ").concat(percentage.toFixed(2), "%") }, { children: [showTextTopRight && (_jsx("div", __assign({ className: "absolute themes-text-gray-v5 text-[12px] top-[-37px] h-4  text-sm text-center w-[50px]", style: { right: '0%' } }, { children: item.textTopRight }))), showTextBotRight && (_jsx("div", __assign({ className: "absolute themes-text-gray-v5 text-[12px] bottom-[-37px] h-4 text-sm text-center flex w-[50px]", style: { right: '0%' } }, { children: item.textBotRight }))), showTextTopLeft && (_jsx("div", __assign({ className: "absolute themes-text-gray-v5 text-[12px] top-[-37px] h-4  text-sm text-center w-[50px]", style: { left: '0%' } }, { children: item.textTopLeft }))), showTextBotLeft && (_jsx("div", __assign({ className: "absolute themes-text-gray-v5 text-[12px] bottom-[-37px] h-4 text-sm text-center flex w-[50px]", style: { left: '0%' } }, { children: item.textBotLeft }))), _jsx("div", __assign({ className: "absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 text-sm text-white" }, { children: item.value >= 1 && item.value })), _jsx("div", { className: "absolute top-[-120%] bottom-[-120%] h-[350%] left-0 w-0.3 border-l-2 border-dashed themes-border-color" })] }), index));
                            }), _jsx("div", { className: "absolute top-[-180%] bottom-[-180%] h-[530%] right-0 w-0.3 border-l-2 border-dashed themes-border-color" })] })) })), _jsx("div", __assign({ className: "flex justify-between" }, { children: intervals.map(function (interval, index) { return (_jsx("div", { children: interval }, index)); }) }))] })) })));
};
export default ProgressBar;
