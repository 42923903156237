import SimpleTable from '@components/simple-table/simple-table.component'
import { bookingPackLinesDummy, metaDummy } from '../booking-details.dummy'
import { packLineHeaders } from '../booking-details.static'
import { IBookingPackLines } from '../booking-details.interface'

const PackLines = () => {
    return (
        <SimpleTable<IBookingPackLines>
            headers={packLineHeaders}
            data={bookingPackLinesDummy}
            usePagination
            nextHandling={(page) => {
                console.log('hit Next API -> ' + page)
            }}
            previousHandling={(page) => {
                console.log('hit Prev API -> ' + page)
            }}
            meta={metaDummy}
        />
    )
}

export default PackLines
