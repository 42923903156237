var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@components/button/button.component";
import { useTranslation } from "react-i18next";
var Header = function (_a) {
    var className = _a.className, title = _a.title, filter = _a.filter, isFilter = _a.isFilter, setIsFilter = _a.setIsFilter;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "".concat(className, " content-center grid grid-cols-12  h-full") }, { children: [_jsxs("div", __assign({ className: "col-span-10 justify-self-start self-center text-gray-400 lg:text-[12px] md:text-xs" }, { children: [_jsx("div", __assign({ className: "self-end  font-bold   ".concat(isFilter ? "themes-text-brand-v5" : "text-gray-400") }, { children: title })), _jsx("div", __assign({ className: "self-start font-normal  ".concat(isFilter ? "themes-text-brand-v5" : "text-gray-400") }, { children: filter }))] })), _jsx("div", __assign({ className: "col-span-2 row-span-2 self-center justify-self-end" }, { children: _jsx(Button, { onClick: function () {
                        setIsFilter(!isFilter);
                    }, label: isFilter ? t("dashboard.save") : t("dashboard.filter"), variant: isFilter ? "brand-v2" : "default", className: "w-[70px] !h-5 !pt-[1px] rounded justify-center items-center gap-2.5 inline-flex  lg:text-[12px] md:text-xs font-bold" }) }))] })));
};
export default Header;
