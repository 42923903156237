import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import { IBookingConsignorOrConsignee } from '../booking-details.interface'
import { useTranslation } from 'react-i18next'

const FormPickupOrDocumentaryAddress = ({
    data,
}: {
    data: IBookingConsignorOrConsignee
}) => {
    const { t } = useTranslation()
    return (
        <div className="mt-3 grid grid-cols-2 gap-3">
            <div className="col-span-2">
                <Dropdown
                    label={t('bookings.company')}
                    defaultValue={[
                        {
                            label: data.company,
                            value: data.company,
                        },
                    ]}
                    disabled={true}
                    options={[
                        {
                            label: data.company,
                            value: data.company,
                        },
                    ]}
                />
            </div>
            <div className="col-span-2">
                <Input
                    label={t('bookings.address')}
                    disabled={true}
                    value={data.address}
                />
            </div>
            <div className="col-span-2">
                <Input
                    label={t('bookings.additional_address')}
                    disabled={true}
                    value={data.additionalAddress}
                />
            </div>
            <div className="col-span-2">
                <Input
                    label={t('bookings.contact_name')}
                    disabled={true}
                    value={data.contactName}
                />
            </div>
            <Input
                label={t('bookings.contact_phone')}
                disabled={true}
                value={data.contactPhone}
            />
            <Input
                label={t('bookings.contact_mobile')}
                disabled={true}
                value={data.contactMobile}
            />
            <div className="col-span-2">
                <Input
                    label={t('bookings.contact_email_address')}
                    disabled={true}
                    value={data.contactEmailAddress}
                />
            </div>
        </div>
    )
}

export default FormPickupOrDocumentaryAddress
