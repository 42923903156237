var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var AvatarCircle = function (_a) {
    var name = _a.name, size = _a.size;
    var getInitials = function (name) {
        var words = name.split(" ");
        var letter = words
            .map(function (word) { return word[0]; })
            .join("")
            .toUpperCase();
        return letter
            .split("")
            .map(function (val, index) {
            if (index < 2)
                return val;
            return "";
        })
            .join("");
    };
    var initials = getInitials(name);
    var circleSize = !size ? "w-8 h-8" : "";
    return (_jsx("div", __assign({ className: "flex items-center justify-center themes-avatar rounded-full ".concat(circleSize), style: { height: size, width: size } }, { children: _jsx("span", __assign({ className: "text-white text-size-S font-semibold ml-[0.9px] mt-1" }, { children: initials })) })));
};
export default AvatarCircle;
