var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./pagination.style.css";
import Button from "@components/button/button.component";
import { useTranslation } from "react-i18next";
function Pagination(_a) {
    var _b, _c;
    var meta = _a.meta, props = __rest(_a, ["meta"]);
    var t = useTranslation().t;
    var indexStart = (meta === null || meta === void 0 ? void 0 : meta.total_Items)
        ? (meta === null || meta === void 0 ? void 0 : meta.total_Items) === 0
            ? 0
            : meta === null || meta === void 0 ? void 0 : meta.index_start
        : 0;
    var indexEnd = (_b = meta === null || meta === void 0 ? void 0 : meta.index_end) !== null && _b !== void 0 ? _b : 0;
    var totalItems = (_c = meta === null || meta === void 0 ? void 0 : meta.total_Items) !== null && _c !== void 0 ? _c : 0;
    var disabled = (meta === null || meta === void 0 ? void 0 : meta.current_page) === (meta === null || meta === void 0 ? void 0 : meta.last_page);
    var nextHandling = (meta === null || meta === void 0 ? void 0 : meta.current_page) ? meta.current_page + 1 : 0;
    var previousHandling = (meta === null || meta === void 0 ? void 0 : meta.current_page) ? (meta === null || meta === void 0 ? void 0 : meta.current_page) - 1 : 0;
    return (_jsx("div", __assign({ className: "pagination-container flex-none" }, { children: _jsx("div", __assign({ className: "flex flex-row" }, { children: _jsxs("ul", __assign({ className: "pagination" }, { children: [_jsx(Button, { icon: "ri-arrow-left-s-line", variant: "default-pagination", type: "button", isDisabled: indexStart <= 1, className: "w-btnIconWidth mx-4", onClick: function () {
                            return props.nextHandling && props.nextHandling(previousHandling);
                        } }), _jsx("li", __assign({ className: "page-of font-[1000]" }, { children: "".concat(indexStart, " - ").concat(indexEnd, " ") })), _jsx("li", __assign({ className: "page-of font-[10]" }, { children: t("additional.of") })), _jsx("li", __assign({ className: "page-of font-[1000]" }, { children: "".concat(totalItems) })), _jsx("li", __assign({ className: "page-of font-[10]" }, { children: t("additional.list") })), _jsx(Button, { icon: "ri-arrow-right-s-line", variant: "default-pagination", type: "button", isDisabled: disabled, className: "w-btnIconWidth mx-4", onClick: function () {
                            return props.nextHandling && props.nextHandling(nextHandling);
                        } })] })) })) })));
}
export default Pagination;
