import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { IShipments } from './shipments.interface'
import useShipments from './shipments.service'
import ShipmentFilterOverlay from './components/shipment-filter-overlay.component'
import { ShipmentStatusHeader, ShipmentSummaryHeader } from './shipments.static'
import { metaDummy, ShipmentDataDummy } from './shipments.dummy'
import ShipmentFilter from './components/shipment-filter.component'
import './shipments.style.css'
import NewBookingOverlay from './components/new-booking-overlay.component'
import { useTranslation } from 'react-i18next'

const Shipments = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        setTabFilter,
        navigate,
        allModal,
        headers,
        ConfigureColumnsModalService,
    } = useShipments()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={ShipmentStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('shipments.shipment_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ShipmentFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-booking')
                        },
                        OverlayComponent: (
                            <NewBookingOverlay
                                overlayService={newBookingOverlayService}
                            />
                        ),
                        label: t('menu.bookings_new').toUpperCase(),
                        className: '!text-size-S',
                    }}
                />

                <Table<IShipments>
                    headers={headers}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Shipment'}
                    onRowClick={() => {
                        navigate('/shipment-details/S00001042')
                    }}
                    enableExport={true}
                    modalService={generateReportModalService}
                    components={<ShipmentFilter isGenerateReport={true} />}
                    resetFilter={() => {}}
                    getDataGenerate={() => {}}
                    generateReportData={ShipmentDataDummy}
                    GenerateReportHeaders={ShipmentSummaryHeader}
                    removeFilter={() => {}}
                />
            </div>
            {allModal}
        </div>
    )
}

export default Shipments
