import { ITabItem } from '@components/tab/tab.interface'
import { TrackingDataDummy } from './trackings.dummy'
import { ITableColumn } from '@components/table/table.interface'
import { IMoreOption } from '@components/table-expandable-detail/table-expandable-detail.interface'

export const TrackingStatusHeader: ITabItem[] = [
    {
        label: 'All Trackings',
        labelTranslationCode: 'status.all_trackings',
        totalData: TrackingDataDummy.length,
        key: 'status',
        value: 'allTrackings',
        className: 'brand',
    },
    {
        label: 'SEA',
        labelTranslationCode: 'status.sea',
        totalData: TrackingDataDummy.filter(
            (x: any) => x.typeTransport === 'SEA',
        ).length,
        key: 'typeTransport',
        value: 'SEA',
        className: 'green',
    },
    {
        label: 'AIR',
        labelTranslationCode: 'status.air',
        totalData: TrackingDataDummy.filter(
            (x: any) => x.typeTransport === 'AIR',
        ).length,
        key: 'status',
        value: 'AIR',
        className: 'yellow',
    },
    {
        label: 'LAND',
        labelTranslationCode: 'status.land',
        totalData: TrackingDataDummy.filter(
            (x: any) => x.typeTransport === 'LAND',
        ).length,
        key: 'status',
        value: 'LAND',
        className: 'red',
    },
]

export const TrackingSummaryDetailHeader: ITableColumn[] = [
    {
        label: 'Tracking Type',
        labelTranslation: 'tracking.tracking_type',
        accessor: 'TrackingType',
    },
    {
        label: 'Tracking Mode',
        labelTranslation: 'tracking.tracking_mode',
        accessor: 'TrackingMode',
    },
    {
        label: 'Shipment ID',
        labelTranslation: 'tracking.shipment_id',
        accessor: 'shipmentID',
    },
    {
        label: 'Weight',
        labelTranslation: 'tracking.weight',
        accessor: 'weight',
    },
    {
        label: 'Carrier',
        labelTranslation: 'tracking.carrier',
        accessor: 'carrier',
    },
    {
        label: 'Vessel',
        labelTranslation: 'tracking.vessel',
        accessor: 'vessel',
    },
    {
        label: 'Voyage / Flight # / Truck Ref',
        labelTranslation: 'tracking.type_transport_code',
        accessor: 'typeTransportCode',
    },
    {
        label: 'Gross Weight Verification Method',
        labelTranslation: 'tracking.method',
        accessor: 'method',
    },
    {
        label: 'Last Edit Time',
        labelTranslation: 'tracking.last_edit',
        accessor: 'lastEdit',
    },
]

export const TabTrackings: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Notes',
        labelTranslationCode: 'tab_details.notes',
        key: 'status',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    {
        label: 'Events',
        labelTranslationCode: 'tab_details.events',
        key: 'status',
        value: 'events',
        className: 'brand',
    },
]

export const trackingMoreOptions: IMoreOption[] = [
    {
        label: 'View Trackings Details',
        value: 'details',
    },
    {
        label: 'eDocs',
        value: 'eDocs',
    },
    {
        label: 'Notes',
        value: 'notes',
    },
    {
        label: 'Delete',
        value: 'delete',
    },
    {
        label: 'Documents',
        value: 'documents',
    },
    {
        label: 'Workflow',
        value: 'workflow',
    },
]
