import AvatarCircle from '@components/avatar-circle/avatar-circle.component'
import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ManageUsersDataDummy } from './manage-users.dummy'
import { IManageUser, IUserActivityLogs } from './manage-users.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const ManageUserStatusHeader: ITabItem[] = [
    {
        label: 'All Users',
        labelTranslationCode: 'status.all_users',
        totalData: ManageUsersDataDummy.length,
        key: 'status',
        value: 'allUsers',
        className: 'brand',
    },
    {
        label: 'Active',
        labelTranslationCode: 'status.active',
        totalData: ManageUsersDataDummy.filter(
            (x: any) => x.userStatus === 'Active',
        ).length,
        key: 'status',
        value: 'Active',
        className: 'green',
    },
    {
        label: 'Suspended',
        labelTranslationCode: 'status.suspended',
        totalData: ManageUsersDataDummy.filter(
            (x: any) => x.userStatus === 'Suspended',
        ).length,
        key: 'status',
        value: 'Suspended',
        className: 'yellow',
    },
]

export const ManageUserHeader: ITableColumn[] = [
    {
        label: 'Image',
        accessor: 'userName',
        showLabel: false,
        exportColumn: false,
        sort: false,
        width: 30, // min width
        customBuild: (data) => {
            return (
                <div>
                    <AvatarCircle name={data} />
                </div>
            )
        },
    },
    {
        label: 'Name',
        accessor: 'userName',
    },
    {
        label: 'User ID',
        accessor: 'userID',
    },
    {
        label: 'Email',
        accessor: 'userEmail',
    },
    {
        label: 'Job Title',
        accessor: 'userJobTitle',
    },
    {
        label: 'Job Category',
        accessor: 'userJobCategory',
    },
    {
        label: 'Role',
        accessor: 'userRole',
    },
    {
        label: 'Organization',
        accessor: 'userOrganizationName',
    },
    {
        label: 'Status',
        accessor: 'userStatus',
    },
    {
        label: 'Last Access',
        accessor: 'userLastActive',
    },
]

export const ManageConfigureColumns: IConfigureColumns<IManageUser>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Image',
                accessor: 'userName',
                showLabel: false,
                sort: false,
                customBuild: (data) => {
                    return (
                        <div>
                            <AvatarCircle name={data} />
                        </div>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Name',
                labelTranslation: 'users.name',
                accessor: 'userName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'User ID',
                labelTranslation: 'users.user_id',
                accessor: 'userID',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.email',
                label: 'Email',
                accessor: 'userEmail',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.job_title',
                label: 'Job Title',
                accessor: 'userJobTitle',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.job_category',
                label: 'Job Category',
                accessor: 'userJobCategory',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.role',
                label: 'Role',
                accessor: 'userRole',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.organization',
                label: 'Organization',
                accessor: 'userOrganizationName',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.status',
                label: 'Status',
                accessor: 'userStatus',
            },
            {
                statusColumn: 'selectedColumns',
                labelTranslation: 'users.last_access',
                label: 'Last Access',
                accessor: 'userLastActive',
            },
        ],
    },
]

export const userActivityHeaders: ITableColumn<IUserActivityLogs>[] = [
    {
        accessor: 'timestamp',
        label: 'Time',
        labelTranslation: 'users.time',
        sort: true,
    },
    {
        accessor: 'module',
        label: 'Module',
        labelTranslation: 'users.module',
        sort: true,
    },
    {
        accessor: 'action',
        label: 'Action',
        labelTranslation: 'users.action',
        sort: true,
    },
    {
        accessor: 'description',
        label: 'Descriptions',
        labelTranslation: 'users.description',
        sort: true,
    },
]
