import { ITabItem } from '@components//tab/tab.interface'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { tabDetailHeaders } from './shipment-details.static'
import Details from './contents/details'
import PackLines from './contents/packLines'
import Orders from './contents/orders'
import RelatedInvoices from './contents/related-invoices'
import Milestones from 'client/ifb/pages/bookings/booking-details/contents/milestones.components'
import Events from 'client/ifb/pages/bookings/booking-details/contents/events.components'
import Tracking from 'client/ifb/pages/bookings/booking-details/contents/tracking.component'
import Notes from 'client/ifb/pages/bookings/booking-details/contents/notes.component'
import Messages from 'client/ifb/pages/bookings/booking-details/contents/messages.component'
import EDocs from 'client/ifb/pages/bookings/booking-details/contents/e-docs.component'
import Documents from 'client/ifb/pages/bookings/booking-details/contents/documents.component'

const useShipmentDetails = () => {
    const navigate = useNavigate()

    // states
    // const [detailsData] = useState<IBookingDetails>(bookingDetails)
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details />,
            packLines: <PackLines />,
            orders: <Orders />,
            relatedInvoices: <RelatedInvoices />,
            documents: <Documents />,
            eDocs: <EDocs />,
            messages: <Messages />,
            notes: <Notes />,
            milestones: <Milestones />,
            events: <Events />,
            tracking: <Tracking />,
        }
        return contents[tabFilter.value]
    }

    return { navigate, getContent, setTabFilter, tabFilter }
}

export default useShipmentDetails
