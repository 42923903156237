var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from "formik";
import Dropdown from "@components/dropdown/dropdown.component";
function FormDropdown(_a) {
    var _b;
    var _c = _a.options, options = _c === void 0 ? [] : _c, _d = _a.parentDivClassName, parentDivClassName = _d === void 0 ? "" : _d, _e = _a.showErrorMessage, showErrorMessage = _e === void 0 ? true : _e, additionalOnClick = _a.additionalOnClick, onlyShowSelectedLabel = _a.onlyShowSelectedLabel, props = __rest(_a, ["options", "parentDivClassName", "showErrorMessage", "additionalOnClick", "onlyShowSelectedLabel"]);
    //formik
    // eslint-disable-next-line no-unused-vars
    var _f = useField(props.name), field = _f[0], meta = _f[1], helpers = _f[2];
    var selectedItem = options.find(function (x) {
        return x.value === field.value;
    });
    var borderColorClass = meta.error && meta.touched ? "themes-border-red" : "";
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError ? "" : "hidden";
    if (onlyShowSelectedLabel)
        return (_jsx("div", __assign({ className: "".concat(parentDivClassName) }, { children: (_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) !== null && _b !== void 0 ? _b : "-" })));
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-3") }, { children: [_jsx(Dropdown, __assign({ options: options, onClick: function (change) {
                    helpers.setValue(change === null || change === void 0 ? void 0 : change.value);
                    additionalOnClick && additionalOnClick(change);
                }, className: " ".concat(props.className), parentDivClassname: borderColorClass, value: selectedItem }, props)), _jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(!showErrorMessage ? "hidden" : errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
}
export default FormDropdown;
