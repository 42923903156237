import Dropdown from '@components/dropdown/dropdown.component'
import { useTranslation } from 'react-i18next'

const TrackingFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'IDJKT - Jakarta, Java',
                            value: 'IDJKT - Jakarta, Java',
                        },
                        {
                            label: 'THBKK - Bangkok',
                            value: 'THBKK - Bangkok',
                        },
                        {
                            label: 'USLAX - Los Angeles',
                            value: 'USLAX - Los Angeles',
                        },
                        {
                            label: 'USNYC - New York',
                            value: 'USNYC - New York',
                        },
                    ]}
                    label={t('quotes.origin_port')}
                    onClick={() => {}}
                    placeholder={t('action.select', {
                        value: t('quotes.origin_port'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default TrackingFilter
