import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { INewsData } from './dashboard.interface'

export const optionDates: IDropdownItem[] = [
    {
        value: 'yesterday',
        label: 'Yesterday',
    },
    {
        value: 'week',
        label: 'Last Week',
    },
    {
        value: '7D',
        label: 'Last 7 Days',
    },
    {
        value: '14D',
        label: 'Last 14 Days',
    },
    {
        value: 'last',
        label: 'Last Month',
    },
    {
        value: 'Month',
        label: 'Last Calendar Month',
    },
    {
        value: '2',
        label: 'Last 2 Mths.',
    },
    {
        value: '3',
        label: 'Last 3 Mths.',
    },
    {
        value: '6',
        label: 'Last 6 Mths.',
    },
    {
        value: '12',
        label: 'Last 12 Mths.',
    },
    {
        value: 'past',
        label: 'In the Past',
    },
]

export const optionDisplay: IDropdownItem[] = [
    {
        value: 'sea',
        label: 'SEA',
    },
    {
        value: 'land',
        label: 'LAND',
    },
    {
        value: 'air',
        label: 'AIR',
    },
]
export const news: INewsData[] = [
    {
        id: '1',
        title: '35-Hour Rail Strike Announced in Germany',
        url: '#',
        description: '',
        news_date: '',
    },
    {
        id: '2',
        title: 'Update: Red Sea & The Freight Market',
        url: '#',
        description: '',
        news_date: '',
    },
    {
        id: '3',
        title: 'Lunar New Year OneByOne Office Closings',
        url: '#',
        description: '',
        news_date: '',
    },
    {
        id: '5',
        title: 'The Situation in the Red Sea and Lunar New Year',
        url: '#',
        description: '',
        news_date: '',
    },
    {
        id: '6',
        title: 'New Office Location in Guangzhou',
        url: '#',
        description: '',
        news_date: '',
    },
]
