export var paymentInvoiceDetail = [
    {
        mode: "dashboard.avg_per_month",
        detail: "US$ 38,585",
    },
    {
        mode: "dashboard.total_costs_overtime",
        detail: "US$ 100,585",
    },
];
export var openInvoice = [
    {
        name: "dashboard.1_within_payment_term",
        value: 18906,
        percentage: 33.15,
        fill: "#CDF7EC",
        textColor: "#048160",
    },
    {
        name: "dashboard.1_week_overdue",
        value: 8806,
        percentage: 24.49,
        fill: "#FFF6E1",
        textColor: "#997D3E",
    },
    {
        name: "dashboard.>1_week_overdue",
        value: 4643,
        percentage: 17.15,
        fill: "#FCDAE2",
        textColor: "#902B43",
    },
];
