import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { useReceiptsDetails } from '../receipts-details.service'
import { linesData, metaDummy } from '../../receipts.dummy'
import { ILines } from '../../receipts.interface'

const Lines = () => {
    const { allModalLines, headersLines, ConfigureColumnsModalServiceLines } =
        useReceiptsDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<ILines>
                headers={headersLines}
                data={linesData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() =>
                                ConfigureColumnsModalServiceLines.openModalHandling()
                            }
                            label="Configure Column"
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalLines}
        </div>
    )
}

export default Lines
