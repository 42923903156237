var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var SortButton = function (_a) {
    var sortOrder = _a.sortOrder, columnKey = _a.columnKey, sortKey = _a.sortKey, onClick = _a.onClick;
    var isActive = sortKey === columnKey;
    return (_jsxs("button", __assign({ onClick: function () { return onClick(columnKey); }, className: "sort-button ".concat(!isActive
            ? 'themes-border-color'
            : 'themes-border-brand-v5') }, { children: [_jsx("i", { className: "".concat(sortKey !== columnKey
                    ? 'ri-subtract-line'
                    : sortKey === columnKey && sortOrder === 'desc'
                        ? 'ri-arrow-down-s-line active'
                        : '') }), _jsx("i", { className: "".concat(sortKey !== columnKey
                    ? ''
                    : sortKey === columnKey && sortOrder === 'desc'
                        ? ''
                        : 'ri-arrow-up-s-line active') })] })));
};
export default SortButton;
