import { useLogin } from './login.service'
import IFBLoginPage from './ifb/ifb-login-page.component'

const Login = (): JSX.Element => {
    const { client } = useLogin()
    if (client === '') {
        return <></>
    } else {
        return <IFBLoginPage />
    }
}

export default Login
