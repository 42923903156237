var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, } from 'recharts';
var JointLineScatterChart = /** @class */ (function (_super) {
    __extends(JointLineScatterChart, _super);
    function JointLineScatterChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JointLineScatterChart.prototype.render = function () {
        var data = this.props.data;
        var keyItem = data.key;
        var monthFormatter = function (tick) {
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[tick - 1];
        };
        return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(ScatterChart, __assign({ margin: {
                    right: 10,
                    left: -40
                } }, { children: [_jsx(CartesianGrid, {}), _jsx(XAxis, { type: "number", dataKey: "x", name: "month", unit: "", domain: [1, 12], tickFormatter: monthFormatter, ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], axisLine: { stroke: 'rgba(0, 0, 0, 0.3)', strokeWidth: 1 }, tickLine: false }), _jsx(YAxis, { type: "number", dataKey: "y", name: "value", unit: "", axisLine: { stroke: 'rgba(0, 0, 0, 0.3)', strokeWidth: 1 } }), _jsx(ZAxis, { type: "number", range: [20] }), _jsx(Tooltip, { cursor: { strokeDasharray: '3 3' } }), keyItem.map(function (item, idx) { return (_jsx(Scatter, __assign({ name: item.id, data: data[item.id], fill: item.color, line: true }, { children: _jsx(LabelList, { dataKey: "y", position: "top" }) }), "data-chart-item-".concat(idx))); })] })) })));
    };
    return JointLineScatterChart;
}(PureComponent));
export default JointLineScatterChart;
