import Button from '@components/button/button.component'
import temporaryLogo from '../temporary-img/card-logo.png'
import { useTranslation } from 'react-i18next'

const SelectedRateCard = ({
    isCreate = false,
    data,
    selectRateOnclick,
}: {
    isCreate?: boolean
    data: {
        originPortCode: string
        originPortName: string
        destinationPortCode: string
        destinationPortName: string
    }
    selectRateOnclick?: () => void
}) => {
    const { t } = useTranslation()
    return (
        <div className="border p-3 rounded leading-[1] themes-text-gray-v5 themes-bg-white">
            <div className="flex justify-between">
                <div className="flex gap-3">
                    <img src={temporaryLogo} className="w-10 h-10" />
                    <div className="mt-1">
                        <div>
                            {t('quotes.expiry_date')} |{' '}
                            <span className="themes-text-red-v3">
                                30 Aug 2024
                            </span>
                        </div>
                        <div className="themes-text-brand-v5">
                            STD | Mediterranean Shipping Company
                        </div>
                    </div>
                </div>
                <div className="flex mt-1">
                    <div>
                        <div>{data.originPortCode}</div>
                        <div>{data.originPortName}</div>
                    </div>
                    <i className="ri-arrow-drop-right-line text-[2.5rem] mt-[-0.3rem]"></i>
                    <div>
                        <div>{data.destinationPortCode}</div>
                        <div>{data.destinationPortName}</div>
                    </div>
                    {isCreate && (
                        <Button
                            onClick={() =>
                                selectRateOnclick && selectRateOnclick()
                            }
                            label="Select Rate"
                            className="ml-3"
                            variant="brand-inverse"
                        />
                    )}
                </div>
            </div>
            <div className="flex gap-4 mt-2">
                <div>
                    <div>{t('quotes.total_cost')}</div>
                    <div className="themes-text-brand-v5">AED 7,025.49</div>
                </div>
                <div>
                    <div>{t('quotes.sailing_date')}</div>
                    <div>06 May 2024</div>
                </div>
                <div>
                    <div>{t('quotes.transit_time')}</div>
                    <div>16 days</div>
                </div>
                <div>
                    <div>{t('quotes.free_days')}</div>
                    <div>-</div>
                </div>
                <div>
                    <div>{t('quotes.rates_by_forwarder')}</div>
                    <div>Shark Express</div>
                </div>
                <div>
                    <div>{t('quotes.freight_cost')}</div>
                    <div>USD 1,913</div>
                </div>
            </div>
            <div className="text-size-XS">
                {t('quotes.remark_and_inclusion_included')}
            </div>
        </div>
    )
}

export default SelectedRateCard
