var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Overlay = function (_a) {
    var children = _a.children, isOverlayOpen = _a.isOverlayOpen, className = _a.className;
    var mobileClass = ' sm:w-auto';
    return (_jsx("div", __assign({ className: "".concat(className, " overlay box-shadow fixed border rounded-logistical-radius mt-1 w-[400px] transition-all ease-out themes-bg-white z-[1] right-[30px] ").concat(mobileClass, " ").concat(isOverlayOpen ? '' : 'hidden') }, { children: children })));
};
export default Overlay;
