import Input from '@components/input/input.component'
import Textarea from '@components/text-area/text-area.component'
import { useTranslation } from 'react-i18next'

const Details = () => {
    const { t } = useTranslation()
    return (
        <div className="h-full grid grid-cols-4 gap-3 p-3">
            <div className="flex flex-col gap-3">
                <div>{t('shipments.shipments_information')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.shippers_ref')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.transport_mode')}
                        value={'Sea Freight'}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.container_mode')}
                        value={'Loose'}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.service_level')}
                        value={'STD. Standard'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.packs')}
                        disabled={true}
                        value={'0'}
                    />
                    <Input
                        label={t('shipments.outer_pack_unit')}
                        value={'PLT, Pallet'}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.volume_measure')}
                        value={'10.000 M3'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Textarea
                        label={t('shipments.goods_description')}
                        value={'Lenovo ThinkPad T14s'}
                        disabled={true}
                        containerClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.house_bill')}
                        value={'STHOSE2300002203'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <div>{t('shipments.consignee_pickup_address')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.company')}
                        value={'ABC Company'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.address')}
                        value={'123 road Jakarta Jakarta 000 ID'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.additional_address')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.contact_name')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.contact_phone')}
                        disabled={true}
                        value={''}
                    />
                    <Input
                        label={t('shipments.contact_mobile')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.contact_email_address')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
                <div>{t('shipments.origin')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.port_name')}
                        value={'Jakarta'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.etd_shipment')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.estimated_pickup')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.pickup_requested_by')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.port_transport_advised')}
                        disabled={true}
                        value={''}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.actual_pickup')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <div> {t('shipments.consignee_pickup_address')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.company')}
                        value={'JINDO LOGISTICS PTY LTD'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.address')}
                        value={'888 COLLINS STREET DOCKLANDS VIC 3008 AU'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.additional_address')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.contact_name')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.contact_phone')}
                        disabled={true}
                        value={''}
                    />
                    <Input
                        label={t('shipments.contact_mobile')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.contact_email_address')}
                        value={'jindo@gmail.com'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
                <div>{t('shipments.destination')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.port_name')}
                        value={'Melbourne'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.etd_shipment')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.estimated_delivery')}
                        value={''}
                        disabled={true}
                    />
                    <Input
                        label={t('shipments.delivery_requested_by')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.delivery_port_transport_advised')}
                        disabled={true}
                        value={''}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.delivery_date')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <div>{t('shipments.charges')}</div>
                <div className="grid grid-cols-2 gap-3">
                    <Input
                        label={t('shipments.inco_term')}
                        value={'Ex Works'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.goods_value')}
                        value={'100,000.00'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.goods_value_currency')}
                        value={'AUD, Australian Dollar'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.additional_terms')}
                        value={''}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.insurance_value')}
                        value={'100,000.00'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.insurance_value_currency')}
                        value={'AUD, Australian Dollar'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                    <Input
                        label={t('shipments.charges_apply')}
                        value={'Show Collect Charges'}
                        disabled={true}
                        parentInputClassName="col-span-2"
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
