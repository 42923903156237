import Button from '@components/button/button.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { useTranslation } from 'react-i18next'

const SearchMilestone = () => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="p-3">{t('bookings.search_milestone')}</div>
            <div className="border-b"></div>
            <div className="p-3">
                <Dropdown
                    options={[
                        {
                            label: 'Shipment - S00001580',
                            value: 'Shipment - S00001580',
                        },
                    ]}
                    defaultValue={[
                        {
                            label: 'Shipment - S00001580',
                            value: 'Shipment - S00001580',
                        },
                    ]}
                    label={t('bookings.tracking_number')}
                    onClick={() => {}}
                    placeholder="Select tracking number"
                    isClearable={true}
                    isSearchable={true}
                />
            </div>
            <div className="border-b"></div>
            <div className="flex justify-between p-3 items-center">
                <div className="w-1/2">{t('bookings.filtered_by')}</div>
                <div className="w-1/2">
                    <Dropdown
                        options={[]}
                        label={t('action.add', {
                            value: t('action.filter'),
                        })}
                        onClick={() => {}}
                        placeholder={t('action.select', {
                            value: t('action.filter'),
                        })}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
            </div>
            <div className="border-b"></div>
            <div className="flex gap-3 p-3">
                <div className="w-5/6">
                    <Dropdown
                        options={[
                            {
                                label: 'December',
                                value: 'December',
                            },
                        ]}
                        defaultValue={[
                            {
                                label: 'December',
                                value: 'December',
                            },
                        ]}
                        label={t('bookings.estimated_month')}
                        onClick={() => {}}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
                <div className="w-1/6">
                    <Button
                        onClick={() => {}}
                        icon="ri-delete-bin-line"
                        className="border-none w-full"
                    />
                </div>
            </div>
        </div>
    )
}

export default SearchMilestone
