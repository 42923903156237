import Header from '../components/header.component'
import { useState } from 'react'
import FilterSection from '../components/filter-section.component'
import { INewsData } from '../dashboard.interface'
import SpinnerTable from '@components/spinner-table/spinner-table.component'
import EmptyResult from '@components/empty-result/empty-result.component'
import { useTranslation } from 'react-i18next'

interface TableOpenPOProps {
    data: INewsData[]
    isLoading: boolean
}

const TableOpenPO: React.FC<TableOpenPOProps> = ({ data, isLoading }) => {
    const [isFilter, setIsFilter] = useState<boolean>(false)
    const { t } = useTranslation()
    const loadingMessage = `loading Data . . .`
    const notFoundMessage = `No data found`

    return (
        <div className="grid grid-rows-12 h-full">
            <Header
                className="row-span-1 content-center h-5"
                title={t('dashboard.industry_news')}
                filter={` ${t('dashboard.last_days', {
                    value: 7,
                })}(17/03/24 - 23/03/24)`}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
            />
            {isFilter ? (
                <FilterSection
                    className="grid gap-3 mt-6"
                    displayedInform={true}
                    filterBy={true}
                    dateRange={true}
                />
            ) : (
                <div className="row-span-10 overflow-y-auto mt-[10px] border-t border-gray-300 mb-[-20px]">
                    {isLoading === true ? (
                        <div className="h-full">
                            <SpinnerTable
                                className="w-full"
                                message={loadingMessage}
                            />
                        </div>
                    ) : data.length <= 0 ? (
                        <div className="h-full">
                            <EmptyResult
                                className="w-full"
                                message={notFoundMessage}
                            />
                        </div>
                    ) : (
                        <table className="table-auto w-full">
                            <tbody className="text-gray-700">
                                {data.map((item, index) => {
                                    return (
                                        <tr
                                            key={index + item.id}
                                            className="w-full border-b border-gray-200 items-center pt-1 pb-1 grid grid-cols-12"
                                        >
                                            <td className="line-clamp-3 col-span-11 text-blue-950 text-base font-normal font-['Basis Grotesque Arabic Pro']">
                                                {item.title}
                                            </td>
                                            <td className="col-span-1 pl-[10px] pr-2 border-l border-gray-300">
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="ri-arrow-right-s-line" />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    )
}

export default TableOpenPO
