import TabMaster from '@components/tab-master/tab-master.component'
import useTrackings from './trackings.service'
import {
    TrackingStatusHeader,
    TrackingSummaryDetailHeader,
    trackingMoreOptions,
} from './trackings.static'
import { metaDummy } from './trackings.dummy'
import { ITracking } from './trackings.interface'
import TableExpandableDetail from '@components/table-expandable-detail/table-expandable-detail.component'
import GoogleMap from '@components/google-map/google-map.component'
import TrackingsFilterOverlay from './components/trackins-filter-overlay.component'
import { useTranslation } from 'react-i18next'

const Trackings = () => {
    const { t } = useTranslation()
    const { data, tabFilter, setTabFilter, filterOverlayService } =
        useTrackings()

    return (
        <div className="content-full-height grid grid-cols-12 gap-4">
            <div className="col-span-6 flex flex-col container-global ">
                <TabMaster
                    items={TrackingStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('tracking.shipment_id'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <TrackingsFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                />

                <TableExpandableDetail<ITracking>
                    data={data}
                    loading={false}
                    summaryDetail={TrackingSummaryDetailHeader}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moreOptions={trackingMoreOptions}
                    moduleTitle={'Trackings'}
                    enableExport={false}
                />
            </div>
            <div className="col-span-6 themes-bg-white rounded-[10px] p-[5px] w-full sm:h-[500px] md:h-full  shadow-md ">
                <GoogleMap />
            </div>
        </div>
    )
}

export default Trackings
