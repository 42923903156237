import SimpleTable from '@components/simple-table/simple-table.component'
import { bookingMilestoneDummy, metaDummy } from '../booking-details.dummy'
import { bookingMilestoneHeaders } from '../booking-details.static'
import { IBookingMilestone } from '../booking-details.interface'
import SearchMilestone from '../components/search-milestone.component'

const Milestones = () => {
    return (
        <div className="h-full grid grid-cols-12">
            <div className="col-span-3 border-y-2 flex flex-col justify-between">
                <SearchMilestone />
            </div>
            <div className="col-span-9">
                <SimpleTable<IBookingMilestone>
                    headers={bookingMilestoneHeaders}
                    data={bookingMilestoneDummy}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                />
            </div>
        </div>
    )
}

export default Milestones
