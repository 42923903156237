import * as Yup from 'yup'

// interface for validation
export interface IBookAgainValidation {
    shippersRef: string
    serviceLevel: string
    incoTerm: string
    additionalTerms: string
    goodsValue: string
    goodsValueCurrency: string
    insuranceValue: string
    insuranceValueCurrency: string
    packs: string
    outerPackUnit: string
    volumeMeasure: {
        qty: string
        unit: string
    }
    weightMeasure: {
        qty: string
        unit: string
    }
    goodsDescription: string
    marksAndNumbers: string
}

// form validation YUP for formik
export const bookAgainValidation = Yup.object().shape({
    shippersRef: Yup.string().required("shipper's Ref is required"),
})

// form login initial data
export const bookAgainInitialValue: IBookAgainValidation = {
    shippersRef: '',
    serviceLevel: '',
    incoTerm: '',
    additionalTerms: '',
    goodsValue: '0.0000',
    goodsValueCurrency: '',
    insuranceValue: '0.0000',
    insuranceValueCurrency: '',
    packs: '0',
    outerPackUnit: '',
    volumeMeasure: {
        qty: '0.000',
        unit: 'M3',
    },
    weightMeasure: {
        qty: '0.000',
        unit: 'KG',
    },
    goodsDescription: '',
    marksAndNumbers: '',
}
