interface IHeaderProps {
    className: string;
    title: string;
    filter: string;
}


const Header = ({ className, title, filter }: IHeaderProps) => {
    return (
        <div
            className={`${className} content-center grid grid-cols-12 h-full border-b border-gray-300 py-3`}
        >
            <div className="col-span-10 justify-self-start self-center text-gray-400 lg:text-[12px] md:text-xs">
                <div className={`self-end  font-bold text-gray-400`}>{title}</div>
                <div className={`self-start font-normal text-gray-400 pb-2`}>
                    {filter}
                </div>
            </div>
        </div>
    )
}

export default Header
