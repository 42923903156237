import * as Yup from 'yup'

// interface for validation
export interface ICreateQuoteValidation {
    originPort: string
    destinationPort: string
}

// form validation YUP for formik
export const createQuoteValidation = Yup.object({
    originPort: Yup.string().required('Origin port required'),
    destinationPort: Yup.string().required('Destination port required'),
})

// form initial data
export const createQuoteInitialValue: ICreateQuoteValidation = {
    originPort: '',
    destinationPort: '',
}
