/* eslint-disable no-unused-vars */

import useCreateBooking from './create-booking.service'

const CreateBooking = () => {
    const { getContent } = useCreateBooking()
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                {getContent()}
            </div>
        </div>
    )
}

export default CreateBooking
