import { useTranslation } from "react-i18next"

const InvoiceFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            {t('additional.work_in_progress')}
        </div>
    )
}

export default InvoiceFilter
