import { IResponseData } from 'common/common.interface'
import { fetch, post, put } from 'common/common.service'
import { ILoginValidation } from 'client/common/form-validation/login.validation'
import { IUserAuth } from 'client/common/repository/interface/user-auth.interface'
import { IUser, IUserActivities } from './interface/user.interface'
import { IUserAccessFilter } from 'client/common/pages/user-acces/user-access.interface'
import { IUserStatusTotal } from './interface/user-status-count.interface'
import { IFRoleModule } from 'client/common/form-validation/role-module.validation'
import { IResetPassword } from 'client/common/form-validation/reset-password.validation'
import { IChangePasswordValidation } from 'client/common/form-validation/change-password.validation'
import { Toast } from '@components/toast/toast.component'
import { IForgotPassword } from 'client/common/form-validation/forgot-password.validation'
import { commonEndpoints } from '../endpoints/common.endpoints'

export const postLogin = async (payload: ILoginValidation) => {
    try {
        const response = await post<IResponseData<IUserAuth>, ILoginValidation>(
            {
                endpoint: commonEndpoints.login,
                payload,
            },
        )
        return response
    } catch (error: any) {
        if (error?.response && error?.response?.status === 400) {
            const response: IResponseData<any> = error?.response?.data
            return response
        } else {
            const err = error as IResponseData<IUserAuth>
            err.isSuccess = false
            return err
        }
    }
}
export const getUserData = async (filter: IUserAccessFilter) => {
    try {
        const response = await fetch<IResponseData<IUser[]>, IUserAccessFilter>(
            {
                endpoint: commonEndpoints.staff_get,
                params: filter,
            },
        )
        return response
    } catch (error: any) {
        throw error.message
    }
}

export const getUserStatusCount = async () => {
    try {
        const response = await fetch<IResponseData<IUserStatusTotal>, any>({
            endpoint: commonEndpoints.staff_getAllStatus,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IUserStatusTotal>
        err.isSuccess = false
        return err
    }
}

export const createUser = async (payload: any) => {
    try {
        const execute = await post<IResponseData<any>, ILoginValidation>({
            endpoint: commonEndpoints.staff_create,
            payload,
        })
        return execute
    } catch (error: any) {
        return error.response.data
    }
}

export const changePassword = async (payload: any) => {
    try {
        await put<IResponseData<any>, IChangePasswordValidation>({
            endpoint: commonEndpoints.staff_changePassword,
            payload,
        })
        Toast({
            header: 'Success',
            message: 'Success Update Password',
            type: 'success',
        })
        return 'success'
    } catch (error: any) {
        Toast({
            header: 'Failed!',
            message: error?.response?.data ?? 'Failed Update Password',
            type: 'error',
        })
        return error.response.data as string
    }
}

export const updateUser = async (id: string, payload: any) => {
    try {
        const execute = await put<IResponseData<any>, ILoginValidation>({
            endpoint: commonEndpoints.staff_update + '/' + id,
            payload,
        })
        return execute
    } catch (error: any) {
        return error.response.data
    }
}

export const getUserDetail = async (uuid: string) => {
    try {
        const response = await fetch<IResponseData<IUser>, { uuid: string }>({
            endpoint: commonEndpoints.staff_get + '/' + uuid,
            params: { uuid },
        })
        return response
    } catch (error: any) {
        return error.response.data
    }
}

export const suspendUser = async (uuid: string) => {
    try {
        const response = await put<IResponseData<any>, { uuid: string }>({
            endpoint: commonEndpoints.staff_delete + '/' + uuid,
            params: { uuid },
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const unSuspendUser = async (uuid: string) => {
    try {
        const response = await put<IResponseData<any>, { uuid: string }>({
            endpoint: commonEndpoints.staff_unsuspend + '/' + uuid,
            params: { uuid },
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const updateModuleUser = async (uuid: string, payload: IFRoleModule) => {
    try {
        const response = await put<IResponseData<any>, IFRoleModule>({
            endpoint: commonEndpoints.staff_updateModule + '/' + uuid,
            payload: payload,
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const forgotPassword = async (payload: IForgotPassword) => {
    try {
        const response = await post<IResponseData<any>, IForgotPassword>({
            endpoint: commonEndpoints.staff_forgotPassword,
            params: payload,
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const postResetPassword = async (payload: IResetPassword) => {
    try {
        const response = await post<IResponseData<any>, IResetPassword>({
            endpoint: commonEndpoints.staff_resetPassword,
            payload,
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const getUserCurrent = async () => {
    try {
        const response = await fetch<IResponseData<any>, IUserAccessFilter>({
            endpoint: commonEndpoints.staff_current,
        })
        return response
    } catch (error: any) {
        console.log(error?.message)
    }
}

export const getUserActivityLogs = async (id: string) => {
    const url: string = `Staff/${id}/activities`
    try {
        const response = await fetch<IResponseData<IUserActivities[]>, null>({
            endpoint: url,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IUserActivities[]>
        err.isSuccess = false
        return err
    }
}
